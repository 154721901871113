$ ->
  loadSearchTabs = () ->
    $.each $("p.search-tab-loader"), (k, v) ->
      me = $(v)
      parentHolder = me.closest('.tab-pane')
      term = me.data('term')
      $.get "/tab/search/#{term}", {
      },
      (data) ->
        parentHolder.append data
        me.html ''

  loadSearchTabs()

  loadFeedTabs = () ->
    $.each $("p.feed-tab-loader"), (k, v) ->
      me = $(v)
      unless me.html() == ''
        parentHolder = me.closest('.tab-pane')
        feedId = me.data('feedid')
        $.get "/tab/feed/#{feedId}", {
        },
        (data) ->
            parentHolder.append data
            me.html ''

  loadFeedTabs()

  loadPinterestTabs = () ->
    $.each $("p.pinterest-tab-loader"), (k, v) ->
      me = $(v)
      parentHolder = me.closest('.tab-pane')
      username = me.data('username')
      $.get "/tab/pinterest/#{username}", {
      },
      (data) ->
        parentHolder.append data
        me.html ''

  loadPinterestTabs()

  loadFeeds = () ->
    $.each $("p.feeds-loader"), (k, v) ->
      me = $(v)
      parentHolder = me.closest('ul.feeds')
      feedId = me.data('feedid')
      $.get "/tab/edit_feed/#{feedId}", {
      },
      (data) ->
        parentHolder.append data
        me.remove()

  loadFeeds()

  $('body').on 'click', '.btn-refresh', (e) ->
    e.preventDefault()
    $(this).closest('.tab-pane').find('nav, .page, .media.media-list').remove()
    $(this).closest('.tab-pane').find('p.feed-tab-loader').html 'Loading feed items...'
    loadFeedTabs()