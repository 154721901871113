class @Utils
  scaleCoord: (val, c1, c2) ->
     (c1/c2)*val

  showCoords: (c, img) ->
    $('#x').val(this.scaleCoord(c.x, img[0].naturalWidth, img.width()))
    $('#y').val(this.scaleCoord(c.y, img[0].naturalHeight, img.height()))
    $('#x2').val(this.scaleCoord(c.x2, img[0].naturalWidth, img.width()))
    $('#y2').val(this.scaleCoord(c.y2, img[0].naturalHeight, img.height()))
    $('#h').val(this.scaleCoord(c.h, img[0].naturalHeight, img.height()))
    $('#w').val(this.scaleCoord(c.w, img[0].naturalWidth, img.width()))

  generateIdenticon: (target) ->
    p1 = Math.random().toString(16).slice(2)
    p2 = Math.random().toString(16).slice(2)
    p3 = Math.random().toString(16).slice(2)
    p4 = Math.random().toString(16).slice(2)
    $(target).closest('#avatar-identicon').find('#hash').val(p1 + p2 + p3 + p4)
    data = new Identicon($(target).closest('#avatar-identicon').find('#hash').val(), 420).toString()
    $(target).closest('#avatar-identicon').find('img.identicon').attr('src', "data:image/png;base64,#{data}")

  @btnLoading: (btn, status = true) ->
    if status
      btn.addClass('disabled')
      btn.append('<span class="p-loading-icon">&nbsp;<span class="fa fa-spinner fa-spin"></span></span>')
    else
      btn.removeClass('disabled')
      btn.find('.p-loading-icon').remove()

  @generateUUID: ->
    # Generate UUID
    id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) ->
      r = Math.random() * 16 | 0
      v = if c == 'x' then r else r&0x3 | 0x8
      return v.toString(16)
    )

window.Utils = @Utils;
