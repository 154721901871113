class @UserRegistrations
  constructor: ->
    $('body').on 'click', '[name=have_invite_code]', (e)->
      selectedRadio = $('[name=have_invite_code]:checked')
      if selectedRadio.val()=='no'
        $('.invite_code_field').hide();
        $('.invite_request_field').show();
      else
        $('.invite_code_field').show();
        $('.invite_request_field').hide();

window.UserRegistrations = @UserRegistrations;
