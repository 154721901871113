$ ->
  $('#toggle-collaborate-filter').on 'click', (e) ->
    e.preventDefault()
    link = $(this)
    if $(this).find(".fa-caret-down").hasClass "fa-rotate-180"
      $(this).siblings('fieldset').find('select, label, a').fadeOut () ->
        link.siblings('label').fadeIn()
    else
      $(this).siblings('label').fadeOut () ->
        link.siblings('fieldset').find('select, label, a').fadeIn()
    $(this).find(".fa-caret-down").toggleClass "fa-rotate-180"

  $('#grade_select, #subject_select').on 'change', (e) ->
    # Get grade and subject
    grade = $('#grade_select').find(':selected').val()
    subject = $('#subject_select').find(':selected').val()
    # Define regex to replace grade and subject params
    grade_regex = /grade=\d*/
    subject_regex = /subject=\d*/
    # Do replacement
    url = $(this).closest('fieldset').find('a').attr('href')
    url = url.replace(grade_regex, "grade=#{grade}")
    url = url.replace(subject_regex, "subject=#{subject}")
    $(this).closest('fieldset').find('a').attr('href', url)