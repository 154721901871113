class @AssetAdd
  constructor: ->
    # ********** VARIABLES **********
    self = @
    @modal = $('#modalAddAssets')
    @form = @modal.find('.tab-pane.active form')
    @submitButtons = @modal.find('.modal-footer .btn')
    @fileList = new Array()
    @uploadList = @modal.find('.uploaded')
    @progressBar = @modal.find('.progress .progress-bar')
    @locationSelector = {
      map: null
      search: null
      markers: []
    }
    @options = {
      context_type: self.modal.data('context-type')
      context_id: self.modal.data('context-id')
      network_id: self.modal.data('network-id')
      asset_attachable:
        type: null
        id: null
      network_digital_attachment:
        from_attachment: false
    }
    # ********** / VARIABLES **********
    @modal.on 'click', '.save-and-close:not(.disabled), .save-and-view:not(.disabled)', ->
      # Disable buttons and show loading icons
      buttons = self.modal.find('[class*="save-and-"]')
      Utils.btnLoading(buttons)
      self.attachAsset(self, $(this))
      # Reset modal to initial state when it is closed
      self.modal.on 'hidden.bs.modal', ->
        self.reset(self, true)


    @modal.on 'data-editor-change', ->
      self.options.network_digital_attachment.from_attachment = true unless self.modal.data('no-attach')
      self.options.asset_attachable.type = self.modal.data('content-block-type')
      self.options.asset_attachable.id = self.modal.data('content-block-id')
      self.form = $('#modal-add-assets').find('form')
      self.reset(self)
    
  # ********** / GENERAL **********

  # Reset the form to its initial state
  formReset: (form) ->
    form[0].reset()

  # Reset the modal to its initial state
  reset: (self, modalClose=false) ->
    # Alerts
    self.form.find('.alert').addClass('d-none')

    # Form
    self.formReset(self.form)

    # Submit buttons
    self.submitButtons.addClass('disabled')
    self.initializeAssetAttach(self)
    # Editor indicator
    if modalClose
      id = self.modal.attr('data-editor')
      $("[data-editor='#{id}']").removeAttr('data-editor')
      self.options.network_digital_attachment.from_attachment = false
      self.options.asset_attachable.type = null
      self.options.asset_attachable.id = null
      
  initializeAssetAttach: (self) ->
    # Unbind events
    self.form.off('change.assetAdd').off('keyup.assetAdd').off('click.assetAdd')

    # Clear search results
    self.form.find('.network-digital-asset-results .results-list > li:not(.network-digital-asset-template)').remove()
    self.form.find('.network-digital-asset-results .results-list').parent().addClass('d-none')
    self.form.find('.network-digital-asset-results > .loading').addClass('d-none')

    # Validation
    self.form.on 'change.assetAdd', 'input[type="radio"]', ->
      self.submitButtons.removeClass('disabled')
    # Run a search for library resources
    handleAssetSearch = (self, e) ->
      e.preventDefault()
      # Get search query
      query = self.form.find('[name="network_digital_assets_search"]').val()
      # Perform search
      self.searchNetworkDigitalAssets(self, query, self.form.find('.btn-network-digital-asset-search'))

    self.form.on 'keyup.assetAdd', '[name="network_digital_asset_search"]', (e) ->
      handleAssetSearch(self, e) if e.keyCode == 13
    self.form.on 'click.assetAdd', '.btn-network-digital-asset-search', (e) ->
      handleAssetSearch(self, e)

  searchNetworkDigitalAssets: (self, query, btn) ->
    # Don't do anything if the search query is empty
    return if query == ''

    # Hide result list
    self.form.find('.network-digital-asset-results .results-list').parent().addClass('d-none')

    # Show loading indicators
    Utils.btnLoading(btn)
    loading = self.form.find('.loading')
    loading.removeClass('d-none')

    # Hide previous errors
    self.form.find('.alert').addClass('d-none')
    # Remove results from previous search
    self.form.find('.network-digital-asset-results .results-list > li:not(.network-digital-asset-template)').remove()

    # Reset form since new resources will be loaded
    self.formReset(self.form)
    self.form.find('[name="network_digital_asset_search"]').val(query)
    self.submitButtons.addClass('disabled')

    # AJAX call to retrieve resources
    $.ajax
      url: Routes.network_digital_assets_path()
      dataType: 'json'
      data:
        search: query
      success: (data) ->
        # Loading is finished
        loading.addClass('d-none')
        # Render library resources
        for asset in data.items
          html = self.form.find('.network-digital-asset-template').clone().removeClass('network-digital-asset-template d-none')
          html.find('input[name="network_digital_asset_id"]').val(asset['id'])
          html.find('input[name="network_digital_asset_id"]').attr('id', "network_digital_asset_#{asset['id']}")
          html.find('.checkbox-label').attr('for', "network_digital_asset_#{asset['id']}")
          html.find('.title').html(asset['title'])
          html.find('.description').html(asset['description'])
          html.find('.preview').attr('src', Routes.preview_network_digital_asset_path(asset['id'], 'medium'))
          if asset['container_id'] == null
            html.find('li .fa').removeClass('fa-{{icon}}').addClass('fa-user green')
            html.find('.asset-type-personal, .share-warning').removeClass('d-none')
          else
            html.find('li .fa').removeClass('fa-{{icon}}').addClass('fa-group blue')
            html.find('.asset-type-group').removeClass('d-none')
          self.form.find('.network-digital-asset-results .results-list').append(html)
          # Display results list
          self.form.find('.network-digital-asset-results .results-list').parent().removeClass('d-none')
      complete: ->
        Utils.btnLoading(btn, false)

  _attachAsset: (self, ajaxData, featurePreviewRoute, noAttach, button) ->
    # Hide error message
    errorAlert = self.form.find('.alert-danger')
    errorAlert.addClass('d-none')
    # Had to pull this out because closing the modal was wiping the editor id
    editor = $('form[data-editor="' + self.modal.attr('data-editor') + '"]').find('.live-editor')
    # Attach resource
    $.ajax
      url: ajaxData['url']
      method: ajaxData['method']
      dataType: 'json'
      data: ajaxData['data']
      success: (data) ->
        if data['id']
          # Show success message
          self.form.find('.alert-success').removeClass('d-none')
          # Handle adding resource into editor
          url = if noAttach then data['url'] else data['network_digital_asset']['url']
          if url == null then url = '#' else url += "' target='_blank"
          # Restore cursor/selection
          editor.froalaEditor('selection.restore')
          $.ajax
            url: Routes[featurePreviewRoute](data['id'])
            data:
              text: editor.froalaEditor('selection.text')
            success: (html) ->
              editor.froalaEditor('html.insert', html)
              editor.froalaEditor('undo.saveStep')
          editor.trigger('asset.inserted', [editor, data['id']]) unless noAttach
        else
          # Show error message
          errorAlert.html(data['error'])
          errorAlert.removeClass('d-none')
          # Hide success message
          self.form.find('.alert-success').addClass('d-none')
      complete: ->
        # Close modal
        self.modal.modal('hide')
        Utils.btnLoading(button, false)
  attachAsset: (self, button) ->
    # Two cases:
    #   1) Editor that does not have attachments (e.g. group description)
    #   2) Editor that has attachments (e.g. content block)
    if self.modal.attr('data-no-attach')
      # self._attachAsset(self, {
      #   url: Routes.library_resource_path(self.form.find('input[name="library_resource_id"]:checked').val())
      #   method: 'GET'
      #   data: {}
      # }, 'feature_preview_library_resource_path', true, button)
    else
      self._attachAsset(self, {
        url: Routes.asset_attachments_path()
        method: 'POST'
        data:
          asset_attachable_type: self.options.asset_attachable.type
          asset_attachable_id: self.options.asset_attachable.id
          network_digital_asset_id: self.form.find('input[name="network_digital_asset_id"]:checked').val()
      }, 'feature_preview_asset_attachment_path', false, button)
window.AssetAdd = @AssetAdd;
