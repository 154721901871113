class @UserSelection
  constructor: ->
    self = @

    select2BasicOptions = {
      theme: 'bootstrap'
      minimumInputLength: 2
      ajax:
        url: "/users"
        dataType: "json"
        data: self.handleData
        processResults: self.processResults
    }

    # select2 for single user
    $('.select2-user').select2 select2BasicOptions

    # select2 for multiple users
    select2BasicOptions.multiple = true
    $('.select2-users').select2 select2BasicOptions


  handleData: (inp) ->
    f = {
      q: inp['term']
    }
    if this.data('joinable-id')
      f.jid = this.data('joinable-id')
    if this.data('joinable-type')
      f.jt = this.data('joinable-type')
    f

  processResults: (data) ->
    console.log(data)
    res =  []
    $.each data, (index, identity) ->
      res.push({ id: identity.user_id, text: "#{identity.name} (#{identity.email})" })
    results: res

window.UserSelection = @UserSelection;
