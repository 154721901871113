# jQuery Shorthand for document.ready
$ ->

  # Generic select2
  # Need to have this above the rest or else a bunch of select2 fields break
  $('.select2').select2({
    theme: 'bootstrap',
    minimumResultsForSearch: 5
  })

  # TODO: Possibly move towards Gitlab's dispatcher.js.coffee where we only initialize necessary classes
  # TODO: Need to figure out what to do for file upload, ResourceAdd, daybooks others now that jquery-ui is gone
  new ContainerProfile()
  try
    new WysiwygEditor()
  catch e
    console.log('Editor failed to load')
    console.log(e)
  new Classroom()
  new Group()
  new Dashboard()
  new EmailThread()
  new Network()
  new Share()
  new Wikis()
  new SiteAdministrationUser()
  new Revision()
  new Taggings()
  new Onboarding()
  new UserSelection()
  new UserRegistrations()
  new LibraryResource()
  window.PEERISTICS_RESOURCE_ADD_HANDLE = new ResourceAdd()
  new AssetAdd()
  new AssetAttachable()
  new NotificationSettings()
  new ResourceAttachable()
  new ContentBlock()
  # window.XXXX = @XXXX;

  # Enable alerts
  $(".alert").alert()

  # Properly set up links with popup confirmation
  $('.confirm-action').each () ->
    self = $(this)
    self.attr('href', self.attr('data-href'))
    self.attr('data-method', self.attr('data-targetmethod')) if self.attr('data-targetmethod')

  # Disable links
  $('body').on 'click', 'a.disabled, li.disabled', (e) ->
    e.preventDefault()
      
  # Panel toggles
  $('a.panel-toggle, .nav-panel .close').on 'click', (e) ->
    e.preventDefault()
    thisPanel = $('.nav-panel')
    if(thisPanel.hasClass('panel-open')) 
      thisPanel.stop().slideUp(200).removeClass('panel-open')
    else 
      thisPanel.stop().slideDown(300).addClass('panel-open')
    $(this).find(".fa-caret-down").toggleClass("fa-rotate-180")


  # Resize all iframes to fit content
  # assume all iframes are videos
  $allVideos = $("iframe")
  $fluidEl = $(".iframe-wrapper")
   
  # Figure out and save aspect ratio for each video
  $allVideos.each((index, item) ->
    thisRef = $(this)
    if thisRef.parent().hasClass('.iframe-wrapper')
      thisRef.parent().data('aspectRatio', this.height / this.width).removeAttr('height').removeAttr('width')
   
   
  # When the window is resized
  $(window).resize () ->
    newWidth = $fluidEl.width()
    # Resize all videos according to their own aspect ratio
    $allVideos.each (index, item)->
      $el = $(this)
      if $el.parent().hasClass('.iframe-wrapper')
        newHeight = newWidth * $el.parent().data('aspectRatio')
        $el.parent().css('min-height', newHeight+"px")
        $el.width(newWidth).height(newHeight)
   
  # Kick off one resize to fix all videos on page load
  ).resize();

  # Temp search field focus
  $('.navbar-main input.form-control').focus () ->
    $('.navbar-main .search-form').addClass('on-focus')

  $('.navbar-main input.form-control').blur ()->
    $('.navbar-main .search-form').removeClass('on-focus')

  # Scrollspy, side navigation menu animated scroll
  $('body').scrollspy({ target: '.scroll-navigation.nav-sub' })
  $(".scroll-navigation a[href^='#']").on 'click', (e) ->
    # prevent default anchor click behavior
    e.preventDefault()

    # animate
    $('html, body').animate({
       scrollTop: $(this.hash).offset().top
      }, 300);

  # Removing already attached resources
  $('ul.list-unstyled.attached_resources').find('a.btn.btn-primary.btn-x').click (e) ->
    e.preventDefault()
    item = $(e.target).parentsUntil('li.attached-resource').parent()
    $('.tooltip').remove()
    item.remove()

  # Do not allow selecting of classes when editing assignment
  if $('#classes').val() != ''
    $('#classes').trigger 'change'

  # Wait for page to load before letting user select avatar type
  $('#selectImage, #selectIdenticon, #selectColour').removeAttr('disabled')

  # Load identicons
  $('img.identicon').each () ->
    thisRef = $(this)
    data = new Identicon(thisRef.next('#hash').val(), 420).toString()
    thisRef.attr('src', "data:image/png;base64,#{data}")
    thisRef.attr('alt', "Identicon")

  # Submit form via link
  $('body').on 'click', 'a.form-submit', (e) ->
    e.preventDefault
    $(this).closest('form').submit()

  # Quick delete element (temp)
  $("body").on "click", ".del-btn", (e) ->
    thisRef = $(this)
    e.preventDefault()
    delElem = thisRef.attr("data-del")
     # thisRef.tooltip "destroy" removing this will allow the pop up to close 
    thisRef.closest(delElem).fadeOut ->
      thisRef.remove()

  # Handle drop down button form controls
  $('body').on 'click', '.btn-input .dropdown-menu li', (e) ->
    $target = $(e.currentTarget)
    $target.closest(".btn-group").find('[data-bind="label"]').html($target.children().html()).end().children(".dropdown-toggle").dropdown "toggle"
    $target.closest(".form-group").find("#selectType").val $target.children().attr("data-value")
    false

  # Sign up button context
  $('#user_signup_form input[name="user[invite_code]"]').on 'input', (e) ->
    thisRef = $(this)
    if thisRef.val() == ''
      thisRef.closest('form').find('button span[id="submit_no_invite"]').show()
      thisRef.closest('form').find('button span[id="submit_with_invite"]').hide()
    else
      thisRef.closest('form').find('button span[id="submit_no_invite"]').hide()
      thisRef.closest('form').find('button span[id="submit_with_invite"]').show()

  # Focus username/email field when log in link is clicked
  $('a#log-in').on 'click', (e) ->
    # setTimeout is needed because:
    # http://stackoverflow.com/questions/16525107/using-bootstrap-dropdown-focus-input-via-jquery#comment23734180_16525261
    setTimeout (->
      $('input#user_login').focus()
    ), 0

  # Utility to ensure we double check if the user wants to perform an action.
  # Usage:
  #   Just add a confirm-action class on the element adn then add a data-text attribute with the text
  #   to prompt the user.
  $('body').on 'click', '.confirm-action', (e) ->
    text = $(this).data('text')
    res = confirm(text)
    return res

  # Search form in main nav
  $('.res-sm-search').find('.btn').on 'click', (e) ->
    $('.search-sm').slideToggle 'fast'
    # Change icon
    $(this).toggleClass('btn-primary btn-warning').find('svg').toggleClass 'd-none'

  # Enable inline form toggles
  $('body').on 'click', '[data-toggle=inline-form-block]', (e) ->
    el = $(this)
    target = el.data('target')
    return false unless target
    $(target).slideToggle('fast')

  # Initialize popover
  $('[data-toggle="popover"], [rel="popover"]').popover
    trigger: 'hover'
    html: true
    container: 'body'
  $('[data-toggle="popoverclick"], [rel="popoverclick"]').popover
    trigger: 'focus'
    html: true
    container: 'body'

  # Utility class to disable on submit
  $('body').on 'submit', '.disable-on-submit', (e) ->
    # find the buttons
    submitButton = $(this).find('button[type=submit], input[type=submit]')
    Utils.btnLoading(submitButton)

  $('[data-toggle="tooltip"]').on 'click', ->
    $(this).blur()

  # Prevent form submission
  $('body').on 'submit', 'form.no-submit', (e) ->
    e.preventDefault()

  $('#toggle-side-nav').on 'click', (e) ->
    $('.side-nav').toggleClass( 'side-nav-open' );

  $('#toggle-search-filters').on 'click', (e) ->
    $('.filter-menu').toggleClass( 'filter-menu-open' );


  $('#pause-carousel').on 'click', (e) -> 
    $(this).addClass("d-none")
    $("#play-carousel").removeClass("d-none")
    $('.carousel').carousel("pause")

  $('#play-carousel').on 'click', (e) -> 
    $(this).addClass("d-none")
    $("#pause-carousel").removeClass("d-none")
    $('.carousel').carousel(
      pause: false)

  $('.back-to-menu-link').on 'click', (e) -> 
    e.preventDefault()
    $('.containerNav').addClass('animation-left').bind 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', ->
      $('.userNav').removeClass('d-none').removeClass('animation-right')
      $('.containerNav').addClass('d-none').removeClass('animation-left')


  $('.forward-to-menu-link').on 'click', (e) -> 
    e.preventDefault()
    $('.userNav').addClass('animation-right').bind 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', ->
      $('.userNav').addClass('d-none')
      $('.containerNav').removeClass('d-none').removeClass('animation-left')


  $(document).on 'hidden.bs.collapse', '#navbarMobile', (e) ->
    if $('.containerNav').hasClass('d-none')
      $('.userNav').addClass('d-none')
    $('.containerNav').removeClass('d-none')
    return

  $('.navbar-toggler').on 'click', (e) -> 
    if $('body').hasClass('fixed-position')
      $('body').removeClass('fixed-position')
    else
      $('body').addClass('fixed-position')


   # When the window is resized
  $(window).resize () ->
    if $(window).width() >= 1200
      $('body').removeClass('fixed-position') 
      $('#navbarMobile').removeClass('show')
      $('.navbar-toggler').attr("aria-expanded","false")
  $('.context_container').addClass('hidden')

  fulltext = $('.context_container .media h2').find('a').attr('title')
   
  truncateString = (str, leng) ->

    if str.length > leng && leng > 5
      str = str.substring(0, leng - 5) + '...'
    else if  leng < 5
      str = str.substring(0, 5) + '...'
    else str + '&nbsp;&nbsp;'

  resizecontainer = () ->
    $('.context_container').removeClass('SecondNavIsOverflowing')
    $('.firstLevelNavigation').removeClass('firstNavIsOverflowing')
    overallWidth = $('.container').outerWidth()
    widthOfName = $('.context_container .media').outerWidth()
    widthOfNav = $('.context_container .navbar-expand-lg ').outerWidth()
    truncatedtext = truncateString(fulltext, ((overallWidth - widthOfNav - 100)/10)  )
    $('.context_container .media h2 a').html(truncatedtext)
    padding = 30
    widthOfName = $('.context_container .media').outerWidth()
    widthOfNav = $('.context_container .navbar-expand-lg').outerWidth() + padding
    if widthOfNav + widthOfName > overallWidth 
      $('.context_container .navbar-expand-lg ').addClass('SecondNavIsOverflowing')
    overallWidth = $('.container').outerWidth()
    $( "div span:first-child" )
    firstLevelNavFirstMenuItemWidth = $('.firstLevelNavigation li:first-child').outerWidth()
    firstLevelNavMenuListWidth = $('.firstLevelNavigation .ml-auto').outerWidth()
    if firstLevelNavMenuListWidth + firstLevelNavFirstMenuItemWidth > overallWidth 
     $('.firstLevelNavigation').addClass('firstNavIsOverflowing')

    # calcualate container name width load

  if window.innerWidth >= 1200
    resizecontainer()

  else
    $('.context_container .media h2 a').html(fulltext)
  $('.context_container').removeClass('hidden')
  $('.firstLevelNavigation').removeClass('hidden')
  
  $(window).resize () ->
    if window.innerWidth >= 1200
      resizecontainer()

    else
      $('.context_container .media h2 a').html(fulltext)
    $('.context_container').removeClass('hidden')
    $('.firstLevelNavigation').removeClass('hidden')

  $(document).on 'show.bs.modal', '.modal-resource-media ', ->
  
    target = this.dataset.id;
    mytab = $("#modal-resource-preview-"+ target)
    iframe = mytab.find("iframe")
    if iframe.length 
      iframe.closest( "div" ).addClass('responsive-container')
      iframe.addClass('responsive-iframe')


  $('.back-to-menu-link').on 'click', (e) -> 
    $('.containerNav').addClass('animation-left').bind 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', ->
      $('.userNav').removeClass('d-none').removeClass('animation-right')
      $('.containerNav').addClass('d-none').removeClass('animation-left')


  $('.forward-to-menu-link').on 'click', (e) ->
    $('.userNav').addClass('animation-right').bind 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', ->
      $('.userNav').addClass('d-none')
      $('.containerNav').removeClass('d-none').removeClass('animation-left')


  $(document).on 'hidden.bs.collapse', '#navbarMobile', (e) ->
    if $('.containerNav').hasClass('d-none')
      $('.userNav').addClass('d-none')
    $('.containerNav').removeClass('d-none')
    return

  $('.navbar-toggler').on 'click', (e) -> 
    if $('body').hasClass('fixed-position')
      $('body').removeClass('fixed-position')
    else
      $('body').addClass('fixed-position')


   # When the window is resized
  $(window).resize () ->
    if $(window).width() >= 1200
      $('body').removeClass('fixed-position') 
      $('#navbarMobile').removeClass('show')
      $('.navbar-toggler').attr("aria-expanded","false")
  $('.context_container').addClass('hidden')

  fulltext = $('.context_container .media h2').find('a').attr('title')
   
  truncateString = (str, leng) ->

    if str.length > leng && leng > 5
      str = str.substring(0, leng - 5) + '...'
    else if  leng < 5
      str = str.substring(0, 5) + '...'
    else str + '&nbsp;&nbsp;'

  resizecontainer = () ->
    $('.context_container').removeClass('SecondNavIsOverflowing')
    $('.firstLevelNavigation').removeClass('firstNavIsOverflowing')
    overallWidth = $('.container').outerWidth()
    widthOfName = $('.context_container .media').outerWidth()
    widthOfNav = $('.context_container .navbar-expand-lg ').outerWidth()
    truncatedtext = truncateString(fulltext, ((overallWidth - widthOfNav - 100)/10)  )
    $('.context_container .media h2 a').html(truncatedtext)
    padding = 30
    widthOfName = $('.context_container .media').outerWidth()
    widthOfNav = $('.context_container .navbar-expand-lg').outerWidth() + padding
    if widthOfNav + widthOfName > overallWidth 
      $('.context_container .navbar-expand-lg ').addClass('SecondNavIsOverflowing')
    overallWidth = $('.container').outerWidth()
    $( "div span:first-child" )
    firstLevelNavFirstMenuItemWidth = $('.firstLevelNavigation li:first-child').outerWidth()
    firstLevelNavMenuListWidth = $('.firstLevelNavigation .ml-auto').outerWidth()
    if firstLevelNavMenuListWidth + firstLevelNavFirstMenuItemWidth > overallWidth 
     $('.firstLevelNavigation').addClass('firstNavIsOverflowing')

    # calcualate container name width load

  if window.innerWidth >= 1200
    resizecontainer()

  else
    $('.context_container .media h2 a').html(fulltext)
  $('.context_container').removeClass('hidden')
  $('.firstLevelNavigation').removeClass('hidden')
  
  $(window).resize () ->
    if window.innerWidth >= 1200
      resizecontainer()

    else
      $('.context_container .media h2 a').html(fulltext)
    $('.context_container').removeClass('hidden')
    $('.firstLevelNavigation').removeClass('hidden')
    
  
  
  


  overallwidth = $('.context_container').innerWidth()
  widthOfName = $('.context_container .media').innerWidth()
  widthOfNav = $('.context_container .navbar-expand-lg ').innerWidth()
  fulltext = $('.context_container .media h2').find('a').attr('title')
  
  truncateString = (str, leng) ->
    if str.length > leng then str.substring(0, leng - 5) + '...' else str

    # calcualate container name width load

  if window.innerWidth >= 1200
    truncatedtext = truncateString(fulltext, ((overallwidth - widthOfNav - 60)/10)  )
    $('.context_container .media h2 a').html(truncatedtext)
  else
    $('.context_container .media h2 a').html(fulltext)
  $('.context_container').removeClass('hidden')
  
  $(window).resize () ->
      # calcualate container name width when browser reloads
    newOverallWidth = $('.context_container').innerWidth() 
    newWidthOfNav = $('.context_container .navbar-expand-lg ').innerWidth()  
    if window.innerWidth >= 1200
      truncatedtext = truncateString(fulltext, ((newOverallWidth - newWidthOfNav - 60)/10)  )
      $('.context_container .media h2 a').html(truncatedtext)
    else 
      $('.context_container .media h2 a').html(fulltext)
    $('.context_container').removeClass('hidden')
  
  $(document).on 'show.bs.modal', '.modal-resource-media ', ->
  
    target = this.dataset.id;
    mytab = $("#modal-resource-preview-"+ target)
    iframe = mytab.find("iframe")
    if iframe.length 
      iframe.closest( "div" ).addClass('responsive-container')
      iframe.addClass('responsive-iframe')


