class @ResourceAttachable
  constructor: ->
    $('body').on 'click', '.content-block .btn-insert', ResourceAttachable.insert

    $('body').on 'click', '.content-block .btn-unattach', ResourceAttachable.remove

    $('body').on 'click', '[data-target^="#modal-attachment-"]', (e) ->
      e.preventDefault()
      self = $(this)

      # Get modal for resource
      target = self.data('target')
      # Only need to render modal if it doesn't exist
      unless $(target).length > 0
        $.ajax
          url: Routes.modal_resource_attachment_path(self.data('id'))
          method: 'GET'
          data:
            type: 'attachment'
          success: (html) ->
            self.closest('body').append(html)
            self.trigger('click')
            modal = $("#modal-attachment-#{self.data('id')}")
            LibraryResource.bindModalEvents(modal)
            # Initialize WYSIWYG editor for description and select2 for tags
            WysiwygEditor.initialize(modal)
            Taggings.initialize_select2(modal)
  @remove: (e) ->
    e.preventDefault()

    # Define useful selectors
    self = $(this)
    contentBlock = self.closest('.content-block')
    form = contentBlock.find('.post-edit, .post-new').find('form')
    tabs = contentBlock.find('.post-tabs')
    id = self.closest('li').find('[data-target^="#modal-attachment-"]').data('id')

    # Delete resource attachment
    $.ajax
      url: Routes.resource_attachment_path(id)
      method: 'DELETE'
      data:
        resource_attachable_type: form.data('type')
        resource_attachable_id: form.data('id')
      success: (data) ->
        listElements = tabs.find("[data-target='#modal-attachment-#{id}']")

        # Remove from lists and update attachment counters
        listElements.each (index) ->
          li = $(this).closest('li')
          ul = li.closest('.attached-resources')
          tab = tabs.find("a[href='#" + ul.closest('.tab-pane').attr('id') + "']")
          li.remove()
          tab.find('.attachment-count').html(ul.find('li').length)

        # Remove modal
        $('body').find("[id='modal-attachment-#{id}']").remove()

        # Remove from editor
        editArea = contentBlock.find('.post-edit .live-editor')
        html = $('<div>' + editArea.froalaEditor('html.get', true) + '</div>')
        html.find("[data-attachment='#{id}'], [data-resource='#{id}']").remove()
        editArea.froalaEditor('html.set', html.html())
        editArea.froalaEditor('undo.saveStep')
      error: (data) ->
        alert data['responseText']

  @insert: (e) ->
    e.preventDefault()

    # Define useful selectors
    self = $(this)
    contentBlock = self.closest('.content-block')
    editArea = contentBlock.find('.post-edit, .post-new').find('.live-editor')
    listElement = self.closest('li')
    id = listElement.find('[data-target^="#modal-attachment-"]').data('id')

    # Make AJAX call to get preview to insert
    $.ajax
      url: Routes.feature_preview_resource_attachment_path(id)
      data:
        text: editArea.froalaEditor('selection.text')
      success: (html) ->
        editArea.froalaEditor('html.insert', html)
        editArea.froalaEditor('undo.saveStep')

  @attach = (id, form) ->
    # Edit tab
    $.ajax
      url: Routes.resource_attachment_path(id)
      data:
        editable: true
      success: (html) ->
        tabs = form.closest('.content-block').find('.post-tabs')
        resourcesList = tabs.find('[id^="edit-attach-"]').find('.attached-resources')
        resourcesList.append($(html))
        tabs.find('a[href^="#edit-attach-"]').find('.attachment-count').html(resourcesList.find('li').length)

    # Show tab
    $.ajax
      url: Routes.resource_attachment_path(id)
      success: (html) ->
        tabs = form.closest('.content-block').find('.post-tabs')
        resourcesList = tabs.find('[id^="show-attach-"]').find('.attached-resources')
        resourcesList.append($(html))
        tabs.find('a[href^="#show-attach-"]').find('.attachment-count').html(resourcesList.find('li').length)

window.ResourceAttachable = @ResourceAttachable;
