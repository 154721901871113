class @SiteAdministrationUser
  constructor: ->
    $('form#userSearch input[name="search_query"]').on 'input', (e) ->
      query = $(this).val()
      table = $(this).closest('form').parent().next().find('> table')
      if query.length < 1
        table.find('tr').show()
      else if query.length < 3
      else
        $.ajax
          url: '/site_administration/users'
          method: 'GET'
          dataType: 'json'
          data:
            q: query
          success: (data) ->
            table.find('tr:not(:first-child)').hide()
            $.each data, (i ,v) ->
              table.find("tr[data-id='#{v.id}']").show()

window.SiteAdministrationUser = @SiteAdministrationUser;
