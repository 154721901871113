# Opens and prefills resource type modal
  $('body').on 'click', '.open-type-modal', (e) ->
    resource = $(this).data('resource')
    resource_id = $(this).data('id')
    name = $("#edit-library-resource-modal-#{resource_id}").find('#network_library_resource_type_name')
    name.val(resource)
    paragraph = $("#edit-library-resource-modal-#{resource_id}").find('#renaming')
    paragraph.text('You are renaming '+ resource)
    type_form = $("#edit-library-resource-modal-#{resource_id}").find("form")
    type_form.attr('action', $(this).data('edit'))
    $("#edit-library-resource-modal-#{resource_id}").modal('show')



  # Opens and prefills network member modal
  $('body').on 'click', '.open-membership-modal', (e) ->
    theModal = $('#modal-user-edit')
    self = $(this)

    $.ajax({
      url: self.data('load-path'),
      method: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        membership_type: self.data('membership-type'),
        membership_id: self.data('membership-id')
      }),
      success: (response) ->
        generic_data = response.generic_data
        # Set all of the data fields
        theModal.find('#membership-modal-id').text('ID ' + generic_data.membership_id)
        theModal.find('#membership-modal-username').text(generic_data.display_name)
        theModal.find('#membership-modal-role-name').text(generic_data.role_name)
        theModal.find('#membership-modal-joined-on').text(generic_data.joined)
        theModal.find('#membership-modal-email').text(generic_data.email)

        registration_wrapper = theModal.find('.modal-registration-wrapper')
        registration_wrapper.empty();
        response.registration_questions.forEach (item) ->
          question_text = item.question
          answer_text = item.answer
          column = $('<div class="col-sm-12">')
          column.append('<h5 class="heading-sm">' + question_text + '</h5>')
          column.append('<p>' + answer_text + '<p>')
          registration_wrapper.append(column)

        onboarding_wrapper = theModal.find('.modal-onboarding-wrapper')
        onboarding_wrapper.empty();
        response.onboarding_questions.forEach (item) ->
          question_text = item.question
          answer_text = item.answer
          column = $('<div class="col-sm-12">')
          column.append('<h5 class="heading-sm">' + question_text + '</h5>')
          column.append('<p>' + answer_text + '<p>')
          onboarding_wrapper.append(column)

        # Avatar
        avatar = theModal.find('#avatar_link')
        avatar.empty()
        avatar.append('<h4 style="margin: 0.5em;">' + self.data('username') + '</h4> ')
        if self.data('avatarCategory') == 'image'
          avatar.append('<img class="avatar avatar-sm" src="' + Routes.avatar_path(self.data('avatarId')) + '">')
        else if self.data('avatarCategory') == 'identicon'
          avatar.append('<img class="identicon avatar avatar-sm"><input id="hash" name="identicon" type="hidden" value="' + self.data('avatarIdenticon') + '">')
          identicon = avatar.find('img.identicon')
          data = new Identicon(identicon.next('#hash').val(), 420).toString()
          identicon.attr('src', "data:image/png;base64,#{data}")
        else if self.data('avatarCategory') == 'image'
          avatar.append('<div class="avatar avatar-sm" style="background-color: ' + self.data('avatarColour') + '"></div>')

        # Role
        network_role = theModal.find('#membership-modal-network-role')
        network_role.find('option[value="' + self.data('roleId') + '"]').prop('selected', true)
        button_text = network_role.closest('form').find('button[type="submit"]')
        button_text.find('span').addClass('d-none')
        if self.data('approved')
          button_text.find('#membership-modal-save').removeClass('d-none')
        else
          button_text.find('#membership-modal-approve').removeClass('d-none')

        # Forms
        forms = theModal.find('.membership-modal-form')
        forms.attr('action', self.data('formPath'))
        forms.find('#membership-modal-delete-id').val(self.data('userId'))

        theModal.modal('show');
      error: (e) ->
        console.log e
    });


  # Opens and prefills network slider modal
  $('body').on 'click', '.open-network-slide-modal', (e) ->
    name = $('#modal-slider-content').find('#network_slider_content_name')
    name.val($(this).data('name'))
    url = $('#modal-slider-content').find('#network_slider_content_url')
    url.val($(this).data('content'))
    description = $('#modal-slider-content').find('#network_slider_content_description')
    description.val($(this).data('description'))
    language = $('#modal-slider-content').find('#network_slider_content_language')
    language.val($(this).data('language'))
    enabled = $('#modal-slider-content').find('#network_slider_content_enabled')
    # Need to turn it into a string here despite the fact that we need to pass a string to this in the first place
    enabled.val(String($(this).data('enabled')))
    position = $('#modal-slider-content').find('#network_slider_content_position')
    position.val($(this).data('position'))
    content = $("#modal-slider-content").find('#content')
    new_content = $("#modal-slider-content").find('#new_content')
    current_slide = $("form[id^='edit_network_slider_content'")
    hidden_field = $('input[name=_method]')
    old_content = $("#modal-slider-content").find('#old_content')
    if $(this).data('content') != ''
      content.attr('src', $(this).data('content'))
      current_slide.attr('id', 'edit_network_slider_content_'+ String($(this).data('id')))
      current_slide.attr('action', $(this).data('edit'))
      hidden_field.attr('value', 'put')
      new_content.attr('d-none', false)
      old_content.attr('d-none', false)
    else
      current_slide.attr('id', 'edit_network_slider_content_new')
      current_slide.attr('action', $(this).data('edit'))
      current_slide.attr('method', "post")
      hidden_field.attr('value', 'post')
      new_content.attr('d-none', false)
      old_content.attr('d-none', true)
    $('#modal-slider-content').modal('show')

    # Opens and prefills share binder modal
  $('body').on 'click', '.btn-share', (e) ->
    destination = $('#modal-share-binder').find('#share_binder_destination')
    destination.val($(this).data('destination'))
    type_form = $('#modal-share-binder').find("form")
    type_form.attr('action', $(this).data('url'))
    $('#modal-share-binder').modal('show')
