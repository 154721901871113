class @ContentBlock
  constructor: ->
    # Creates a new unpublished content block
    $('body').on 'click', '.new-content-block-link', (e) ->
      form = $($(this).data('target')).find('form')

      # No need to create another content block if one already exists
      return unless isNaN(parseInt(form.attr('data-id')))

      $.ajax
        url: form.attr('action')
        method: 'POST'
        dataType: 'json'
        success: (data) ->
          if data['id']
            form.attr('data-id', data['id'])
            form.find('input[name="unpublished_id"]').val(data['id'])
            form.find('input[name="publish_path"]').val(data['publish_path'])

    # Only show new content block button when Javascript has loaded to prevent any erroneous states
    $('body').find('.new-content-block-link').removeClass('d-none')

    # Cancel button for new forum posts
    $('body').on 'click', '.new-content-block .btn-cancel', (e) ->
      e.preventDefault()

      self = $(this)
      form = self.closest('form')
      $.ajax
        url: form.find('input[name="publish_path"]').val()
        method: 'DELETE'
        dataType: 'json'
        success: (data) ->
          form.attr('data-id', null)
          form.find('input[name="unpublished_id"]').val(null)
          form.find('input[name="publish_path"]').val(null)
          self.closest('.new-content-block').slideToggle('fast')

    # Edit button in content block action bar
    # Show _edit and hide _show
    $('body').on 'click', '.edit-content-block', (e) ->
      e.preventDefault()
      $(this).addClass('d-none')
      $(this).closest('.content-block').find('.cancel-content-block').removeClass('d-none')
      viewArea = $(this).closest('.content-block').find('.post-view')
      editArea = viewArea.siblings('.post-edit')
      viewArea.fadeOut().addClass('d-none')
      editArea.fadeIn().removeClass('d-none')

    # Cancel button in edit content block form
    # Show _show and hide _edit
    $('body').on 'click', '.cancel-content-block', (e) ->
      e.preventDefault()
      $(this).addClass('d-none')
      $(this).closest('.content-block').find('.edit-content-block').removeClass('d-none')
      editArea = $(this).closest('.content-block').find('.post-edit')
      viewArea = editArea.siblings('.post-view')
      editArea.fadeOut().addClass('d-none')
      viewArea.fadeIn().removeClass('d-none')

    $('body').on 'click', '.post-edit .btn-cancel', (e) ->
      e.preventDefault()
      $(this).closest('.content-block').find('.cancel-content-block').addClass('d-none')
      $(this).closest('.content-block').find('.edit-content-block').removeClass('d-none')
      editArea = $(this).closest('.content-block').find('.post-edit')
      viewArea = editArea.siblings('.post-view')
      editArea.fadeOut().addClass('d-none')
      viewArea.fadeIn().removeClass('d-none')

    # When ContentBlock submit buttons are clicked, this event handler wires up the
    # status dropdown before the form submits anything
    $('body').on 'click', '.content-block .btn-submit', (e) ->
      buttonClicked = $(e.target)
      # Use the button clicked to determine what status to send for the post
      status = buttonClicked.data('status')
      buttonClicked
        .closest('fieldset')
        .find('select[name="forum_post\[status\]"]')
        .val(status)

      statusSelect = buttonClicked.siblings('select')
      if statusSelect.length > 0
        statusSelect.prop('selectedIndex', buttonClicked.data('status'))
      $(this).closest('.content-block').find('.cancel-content-block').addClass('d-none')
      $(this).closest('.content-block').find('.edit-content-block').removeClass('d-none')

    # After submitting the form via AJAX, hide _edit and display _show
    $('body').on 'submit', '.content-block-ajax .post-edit form', (e) ->
      e.preventDefault()
      form = $(this)
      editArea = form.closest('.content-block').find('.post-edit')
      viewArea = form.closest('.content-block').find('.post-view')

      Peeristics.ajaxContentBlock form, () ->
        # Update view on success
        content = editArea.find('textarea.live-editor').val()
        viewArea.find('.view-area .fr-view').html(content)

        # Swap visible view back to the view-area since we're done editing.
        editArea.fadeOut().addClass('d-none')
        viewArea.fadeIn().removeClass('d-none')

    # Tab actions
    $('body').on 'click', '.content-block .post-tabs ul.nav li a', (e) ->
      e.preventDefault()

      self = $(this)
      tabLink = self
      tabPane = $(self.attr('href'))
      tabs = self.closest('.post-tabs')
      tabContent = tabs.find('.tab-content')

      if self.parent().hasClass('active')
        # Active tab has been clicked, so close it
        self.parent().removeClass('active')
        tabPane.slideUp('fast').removeClass('in')
      else
        # New tab has been clicked
        # Reset active tab
        tabs.find('li.active').removeClass('active')
        # Slide up any active tab panes before sliding down selected tab pane
        if tabContent.find('.tab-pane.in').length > 0
          tabContent.find('.tab-pane.in').slideUp('fast', ->
            tabPane.slideDown('fast').addClass('in')
            tabLink.parent().addClass('active')
          ).removeClass('in')
        else
          tabPane.slideDown('fast').addClass('in')
          tabLink.parent().addClass('active')

    # Auto-save form when a select field's value is changed
    $('body').on 'change', '.content-block-ajax form select:not([id*="-vue"])', (e) ->
      e.preventDefault()
      Peeristics.ajaxContentBlock($(this).closest('form'))

    $('body').on 'click', '.content-block-ajax form .objectives-vue input[type=checkbox]', (e) ->
      Peeristics.ajaxContentBlock($(this).closest('form'))

window.ContentBlock = @ContentBlock;
