$ ->

  $('#select-image').on 'change', (e) ->
    e.preventDefault()
    $('#avatar-image').removeClass('d-none')
    $('#avatar-identicon').addClass('d-none')
    $('#avatar-colour').addClass('d-none')
#    $('#avatar-type').val('image')

  $('#select-identicon').on 'change', (e) ->
    e.preventDefault()
    $('#avatar-image').addClass('d-none')
    $('#avatar-identicon').removeClass('d-none')
    $('#avatar-colour').addClass('d-none')
#    $('#avatar-type').val('identicon')

  $('#select-colour').on 'change', (e) ->
    e.preventDefault()
    $('#avatar-image').addClass('d-none')
    $('#avatar-identicon').addClass('d-none')
    $('#avatar-colour').removeClass('d-none')
#    $('#avatar-type').val('colour')

  $('#generate').on 'click', (e) ->
    e.preventDefault()
    u = new Utils()
    u.generateIdenticon(e.target)
