
// ORDER IMPORTANT

require('../../coffee/peeristics.js.coffee');
require('../../coffee/content_block.js.coffee');
require('../../coffee/config.js.coffee');
require('../../coffee/utils.js.coffee');

// ORDER NOT IMPORTANT

require('../../coffee/avatars.js.coffee');

require('../../coffee/binders/binder_sections.js.coffee');
require('../../coffee/binders/binders.js.coffee');

require('../../coffee/collaborate.js.coffee');
require('../../coffee/comment.js.coffee');
// require('../../coffee/config.js.coffee');
require('../../coffee/container_profile.js.coffee');

require('../../coffee/containers/classroom.js.coffee');
require('../../coffee/containers/group.js.coffee');

// require('../../coffee/content_block.js.coffee');
require('../../coffee/dashboard.js.coffee');
require('../../coffee/daybook.js.coffee');
// require('../../coffee/document.ready.js.coffee');
require('../../coffee/email_thread.js.coffee');
require('../../coffee/library_resource.js.coffee');
require('../../coffee/modal.js.coffee');
require('../../coffee/network.js.coffee');
require('../../coffee/objects_membership.js.coffee');
require('../../coffee/onboarding.js.coffee');
// require('../../coffee/peeristics.js.coffee');
require('../../coffee/resource.js.coffee');
require('../../coffee/resource_add.js.coffee');
require('../../coffee/resource_attachable.js.coffee');
require('../../coffee/network_digital_asset.js.coffee');
require('../../coffee/asset_add.js.coffee');
require('../../coffee/asset_attachable.js.coffee');
require('../../coffee/revision.js.coffee');
require('../../coffee/routes.js.coffee');
require('../../coffee/share.js.coffee');

require('../../coffee/site_administration/user.js.coffee');

require('../../coffee/site_administration.js.coffee');
require('../../coffee/taggings.js.coffee');
require('../../coffee/tours.js.coffee');
require('../../coffee/user_dashboard_tab.js.coffee');
require('../../coffee/user_registrations.js.coffee');

require('../../coffee/users/notification_settings.js.coffee');
require('../../coffee/users/user_selection.js.coffee');

// require('../../coffee/utils.js.coffee');
require('../../coffee/wikis.js.coffee');
require('../../coffee/wysiwyg_editor.js.coffee');


// ORDER IMPORTANT:

require('../../coffee/document.ready.js.coffee');

