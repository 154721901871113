import Vue from 'vue';
import VueI18n from 'vue-i18n';
import customValidationMessages from './_locales/errorMessages.json';

Vue.use(VueI18n);

// combine default & custom messages
const messagesEN = {
  code: 'en',
  messages: { 
    ...customValidationMessages.en
  }
};

const messagesFR = {
  code: 'fr',
  messages: { 
    ...customValidationMessages.fr
  }
};

// Load the language from the html attribute
const htmlTag = document.getElementsByTagName('html')[0]
let locale = 'en'

if(htmlTag && htmlTag.getAttribute('lang')) {
    locale = htmlTag.getAttribute('lang');
}

// export i18n with validation messages
export default new VueI18n({
  locale: locale || 'en',
  messages: {
    en: {
      validation: messagesEN
    },
    fr: {
      validation: messagesFR
    }
  }
});

window.addEventListener('DOMContentLoaded', (event) => {
  const i18n = new VueI18n({ locale: locale });
  const app = new Vue({ i18n, el: '#peeristics-app-body' });
});

