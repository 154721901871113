class @Routes
  @resource_attachments_path: ->
    '/attachments'

  @resource_attachment_path: (id) ->
    "/attachments/#{id}"

  @modal_resource_attachment_path: (id) ->
    "/attachments/#{id}/modal"

  @preview_resource_attachment_path: (id, size='master') ->
    "/attachments/#{id}/preview/#{size}"

  @feature_preview_resource_attachment_path: (id) ->
    "/attachments/#{id}/feature_preview"

  @asset_attachments_path: ->
    '/asset_attachments'

  @asset_attachment_path: (id) ->
    "/asset_attachments/#{id}"

  @modal_asset_attachment_path: (id) ->
    "/asset_attachments/#{id}/modal"

  @preview_asset_attachment_path: (id, size='medium') ->
    "/asset_attachments/#{id}/preview/#{size}"

  @feature_preview_asset_attachment_path: (id) ->
    "/asset_attachments/#{id}/feature_preview"
    
  @avatar_path: (id) ->
    "/avatars/#{id}"

  @library_resources_path: ->
    '/resources'

  @library_resource_path: (id) ->
    "/resources/#{id}"

  @preview_library_resource_path: (id, size='master') ->
    "/resources/#{id}/preview/#{size}"
  
  @generate_public_preview_library_resource_path: (id) ->
    "/resources/#{id}/public_library_resource_token"

  @public_preview_library_resource_path: (id, token) ->
    "#{window.location.protocol}//#{window.location.host}/resources/#{id}/tokens/#{token}"

  @feature_preview_library_resource_path: (id) ->
    "/resources/#{id}/feature_preview"

  @network_digital_assets_path: () ->
    "/assets"

  @network_digital_asset_path: (id) ->
    "/assets/#{id}"

  @preview_network_digital_asset_path: (id, size='master') ->
    "/assets/#{id}/preview/#{size}"

  @feature_preview_network_digital_asset_path: (id) ->
    "/assets/#{id}/feature_preview"


  @modal_library_resource_path: (id) ->
    "/resources/#{id}/modal"

  @attachment_library_resource_path: (id) ->
    "/resources/#{id}/attachment"

  @content_path: (ct, id) ->
    if ct == "user"
      "/users/#{id}/contents"
    else
      "/c/#{ct}/#{id}/contents"

  @group_members_path: (id) ->
    "/groups/#{id}/member"

  @classroom_content_path: (id) ->
    "/classrooms/#{id}/contents"

  @google_user_content_path: (username, folder) ->
    url = "/users/#{username}/contents/google"
    if folder then url += "?folder=#{folder}"
    return url

  @binder_path: (id) ->
    "/binders/#{id}"

  @forum_channels_path: (container_type, id) ->
    "/#{container_type}/#{id}/forum/channels"

  @user_subscription_path: (username, id) ->
    "/users/#{username}/subscriptions/#{id}"

window.Routes = @Routes;
