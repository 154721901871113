$ ->
  # Binder section ordering
  $('body').on 'click', '.btn-section-directions', (e) ->
    e.preventDefault()
    self = $(this)
    direction = $(this).children('a').data('direction')
    # Determine if this is a valid move
    switch direction
      when 'up' then error = self.closest('li').is(':first-child')
      when 'down' then error = self.closest('li').is(':last-child')
    # If invalid, show error message
    if error
      $('#section-move-errors').children().hide()
      $("#section-move-errors .#{direction}").show()
      $('#section-move-errors').show()
      $('#section-move-success').children().hide()
      $('#section-move-success').hide()
      return
    # Otherwise, proceed with move
    # Disable direction buttons while move is happening to prevent users from sending multiple requests at once
    $('.btn-wiki-directions').prop('disabled', true)
    $.ajax
      url: $(this).children('a').attr('href')
      method: 'post'
      dataType: 'json'
      data:
        direction: direction
      success: (data) ->
        # Update DOM to reflect move
        switch direction
          when 'up'
            destination = self.closest('li').prev()
            section = self.closest('li').detach()
            destination.before(section)
          when 'down'
            destination = self.closest('li').next()
            section = self.closest('li').detach()
            destination.after(section)
        # Enable direction buttons and hide error message
        $('.btn-wiki-directions').prop('disabled', false)
        $('#section-move-errors').children().hide()
        $('#section-move-errors').hide()
        $('#section-move-success').children().show()
        $('#section-move-success').show()
$('.show-content-block').click (e) ->
    e.preventDefault()
    $(this).closest('li').find('.sub-section-content').slideToggle 'fast', (e) ->
      $(this).closest('li').find('.sub-section-content-form').slideToggle 'fast'

  $('.cancel-show-content-block').click (e) ->
    e.preventDefault()
    $(this).closest('li').find('.sub-section-content-form').slideToggle 'fast', (e) ->
      $(this).closest('li').find('.sub-section-content').slideToggle 'fast'
