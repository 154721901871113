class @Taggings
  constructor: ->
    # Load up tags using select2
    Taggings.initialize_select2()

  @initialize_select2: (base) ->
    base ||= $('body')

    base.find('.select2-tags').select2
      tags: true
      closeOnSelect: true
      theme: 'bootstrap'
      multiple: true
      tokenSeparators: [',']
      # TODO: Commented this out because it breaks the tags, gonna have to look into it later.
#      ajax:
#        url: "/tags"
#        dataType: "json"
#        data: (inp) ->
#          search_term: inp['term']
#        processResults: (data) ->
#          res =  []
#          $.each data, (i,v) ->
#            res.push({
#              id: v,
#              text: v
#            })
#          results: res

    # Add tags to the visual list
    base.find('.select2-tags').change (e) ->
      selectTags = $(this)
      tabs = $(e.target).closest('.content-block').find('.post-tabs').find('a[href^="#edit-tag-"], a[href^="#show-tag-"]')
    
    # Add aria-label to tages and change type
    $(".select2-search__field").attr("aria-label", "Add a tag");
    $(".select2-search__field").attr("type", "text");

window.Taggings = @Taggings;
