// Set up Font Awesome
import { IconDefinition, IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';
import { library } from '@fortawesome/fontawesome-svg-core';

// Import each icon
import { faCircle, faDotCircle, faSquare, faTrashAlt, faClock, faCommentAlt, faCommentExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare, faSave, faCheck, faFolder, faFileAlt, faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { faSearch, faPlus, faEdit, faCog, faHistory } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faTimes as fasTimes, faCommentAltCheck } from '@fortawesome/pro-solid-svg-icons';
import { faUser as farUser, faExclamationCircle, faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {faChevronRight, faChevronDoubleLeft, faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons';

// custom Liver icon using a custom 'fac' as the icon prefix

var faBolt = {
  prefix: "fac",
  iconName: "circle-bolt",
  icon: [512, 512, [], 'e001', 'M313.6 131.2C319.5 135.6 321.6 143.5 318.7 150.3L280.3 240H344C350.8 240 356.8 244.2 359.1 250.6C361.3 256.1 359.4 264.1 354.2 268.4L218.2 380.4C212.5 385 204.3 385.2 198.4 380.8C192.5 376.4 190.4 368.5 193.3 361.7L231.7 271.1H168C161.2 271.1 155.2 267.8 152.9 261.4C150.7 255 152.6 247.9 157.8 243.6L293.8 131.6C299.5 126.1 307.7 126.8 313.6 131.2V131.2zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z']
};


// DIAG: For any TS error's with a font
// https://github.com/FortAwesome/Font-Awesome/issues/12575

// Add each icon to libary
library.add(
  faPlus,
  faTrashAlt,
  faEdit,
  faCog,
  faHistory,
  fasTimes,
  farUser,
  faSave,
  faSearch,
  faList,
  faCaretDown,
  faChevronRight,
  faCheckSquare,
  faSquare,
  faDotCircle,
  faCircle,
  faCheck,
  faFolder,
  faFileAlt,
  faClock,
  faCommentAlt,
  faCommentAltCheck,
  faBolt,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faCalendarAlt,
  faExclamationCircle,
  faChevronUp,
  faChevronDown,
  faCommentExclamation
);
