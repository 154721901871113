class @Revision
  constructor: ->
    # Get modal
    @modal = $('#modal-revision')

    # Get components from modal
    @modalContent = @modal.find('.revision-content')
    @modalError = @modal.find('.revision-error')
    @modalLoading = @modal.find('.revision-loading')
    @revertButton = @modal.find('.btn-revision-revert')

    # Alias for instance
    self = @

    # When modal is shown
    @modal.on 'show.bs.modal', (e) ->
      url = $(e.relatedTarget).data('url')
      $.ajax
        url: url
        dataType: 'json'
        accepts:
          html: 'text/html'
          text: 'text/plain'
        success: (data) ->
          self.showContent(data['html'], url)
        error: (error) ->
          self.showError(error.responseText)

    # When modal is hidden
    @modal.on 'hidden.bs.modal', (e) ->
      self.showLoading()

  showContent: (html, url) ->
    @modalContent.html(html).show()
    @revertButton.attr('href', url).show()
    @modalError.add(@modalLoading).hide()

  showError: (error) ->
    @modalError.show()
    @modalError.find('.error-msg').html(error)
    @modalContent.add(@modalLoading).add(@revertButton).hide()

  showLoading: ->
    @modalLoading.show()
    @modalContent.add(@modalError).add(@revertButton).hide()

window.Revision = @Revision;
