class Peeristics
  searchResources: (opts, callback) ->
    $.get Routes.library_resources_path, opts,
    ((data) ->
      resources = $.map data, (resource, index) ->
        {
          id: resource['id']
          value: resource['title']
        }
      callback(resources)
    ), 'json'

  getMembers: (searchTerm, callback, groupId) ->
    $.get Routes.group_members_path(groupId), {
      search_term: searchTerm
    },
    ((data) ->
      members = []
      $.each data, (i ,v) ->
        members.push
          value: v.first_name + " " + v.last_name,
          id: v.id
      callback(members)
    ),
    'json'

  # Update a content block via AJAX
  ajaxContentBlock: (form, callback) ->
    # Get the DOM element containing the content block
    contentBlock = form.closest('.content-block-ajax')

    # Show loading icon
    submitButton = form.find('button[type=submit]')
    Utils.btnLoading(submitButton)

    # Remove trailing anchors in the URL
    action = form.attr('action')
    unless action.lastIndexOf('#') == -1
      action = action.substring(0, form.attr('action').lastIndexOf('#'))
    # Make the AJAX call
    $.ajax
      url: "#{action}.json"
      method: 'POST'
      dataType: 'json'
      data: form.serialize()
      success: (data, textStatus, jqXHR) ->
        # Hide loading icon
        Utils.btnLoading(submitButton, false)

        # Update the title and description
        # data['id'] should only exist if the call succeeded
        # data is the JSON representation of the content block
        if data['id']
          viewArea = contentBlock.find('.post-view')
          viewArea.find('.post-content .heading-content > *').html(data['title'])
          viewArea.find('.post-content .description').html(data['description'])
          # Tags
          tabPane = viewArea.find('.tab-pane[id^="show-tag-"]')
          tagList = tabPane.find('ul')
          tagList.html('')
          if data['tags'].length > 0
            tabPane.find('.blank').addClass('d-none')
            $.each data['tags'], (index, tag) ->
              tagList.append('<li class="badge badge-secondary">' + tag['value'] + '</li> ')
          else
            tabPane.find('.blank').removeClass('d-none')

        # Execute callback, if given
        if callback
          callback()
      error: (data, textStatus, errorThrown) ->
        alert("There was an error saving #{errorThrown}")

  ajaxForm: (form, callback) ->
    # Grab the submit button and change it
    submitButton = form.find('button[type=submit]')
    submitButton.addClass('disabled')
    $.ajax
      url: "#{form.attr('action')}.json"
      method: 'post'
      data: form.serialize()
      success: (data, textStatus, jqXHR) ->
        if callback
          callback(form)
      error: (data, textStatus, errorThrown) ->
        alert("There was an error saving #{errorThrown}")

window.Peeristics = new Peeristics()
