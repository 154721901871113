class @ObjectsMembership
  constructor: ->
    $('ul#roleDropdown li a').on 'click', (e) ->
      e.preventDefault()
      self = $(this)
      self.closest('.input-group-btn').find('.btn:first-child #roleName').text($(this).text())
      self.closest('form').find('input[name*="[network_role_id]"]').val($(this).data('value'))

    $('input[name="network_user_autocomplete"]').autocomplete
      minLength: 3
      source: (request, response) ->
        $.ajax
          url: '/users'
          dataType: 'json'
          data:
            q: request.term
          success: (data) ->
            members = []
            $.each data, (i ,v) ->
              members.push
                value: "#{v.name} (#{v.email})"
                id: v.id
            response(members)
      select: (event, ui) ->
        $(this).closest('form').find('input[name*="[user_id]"]').val(ui.item.id)

    $('input[name="user_autocomplete"]').autocomplete
      minLength: 3
      source: (request, response) ->
        $.ajax
          url: '/site_administration/users'
          method: 'GET'
          dataType: 'json'
          data:
            q: request.term
          success: (data) ->
            members = []
            $.each data, (i ,v) ->
              members.push
                value: "#{v.name} (#{v.email})"
                id: v.id
            response(members)
      select: (event, ui) ->
        $(this).closest('form').find('input[name*="[user_id]"]').val(ui.item.id)

    $('.show-network-role-form').on 'submit', (e) ->
      e.preventDefault()
      form = $(this)
      $.ajax
        url: form.attr('action')
        method: 'PUT'
        dataType: 'json'
        data: form.serialize()
        success: (data) ->
          feedback = form.find('p.feedback')
          feedback.removeClass (index, css) ->
            (css.match(/(^|\s)text-\S+/g) or []).join ' '
          if data['notice']
            form.closest('.modal').find('.role-name').text(data['role_name'])
            $('a[data-target="#' + form.closest('.modal').attr('id') + '"]').closest('.media-body').find('.role-name').text(data['role_name'])
            feedback.addClass('text-success')
            feedback.text(data['notice'])
          else if data['error']
            feedback.addClass('text-danger')
            feedback.text(data['error'])
          else
            feedback.addClass('text-muted')
            feedback.text('Sorry, an unknown error has occurred. Please try again.')

window.ObjectsMembership = @ObjectsMembership;
