# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
$ ->
  # Allow editable Binder Section name
  $('.open-section-title-form').click (e) ->
    e.preventDefault()
    $(this).closest('.heading-block').children('.edit-area').removeClass('d-none')
    $(this).closest('.heading-block').children('.view-area').addClass('d-none')

  $('.close-section-title-form').click ->
    $(this).closest('.heading-block').children('.edit-area').addClass('d-none')
    $(this).closest('.heading-block').children('.view-area').removeClass('d-none')

  # Standardardize form ajaxing
  $("form.section-title-form").on "submit", (event) ->
    event.preventDefault()
    form = $(this)
    Peeristics.ajaxForm form, (e) ->
      newVal = form.find('input[name=binder_section\\[title\\]]').val()
      sectionId = form.data('section')
      form.closest('.heading-block').children('.view-area').children('.heading-section').html(newVal)
      $("#binder-section-menu-#{sectionId} a").html(newVal)
      form.find('button.btn-cancel').click()

  # Hide/show add section
  $('.new-sub-section').on 'click', (e) ->
    e.preventDefault()
    $(this).parent().next('div').slideToggle("fast")

  # Show the show form
  $('body').on 'click','.sub-section-text .btn-show', (e) ->
    e.preventDefault();
    $thisSection = $(this).closest('.sub-section-text');
    $thisForm = $thisSection.next('div');
    $thisSection.fadeOut 'fast', () ->
      # show show form
      $thisForm.fadeIn('fast')

  # Open layout (Binder) cancel text show
  $('body').on 'click','.sub-section-text-form .btn-cancel', (e) ->
    e.preventDefault();
    $thisForm = $(this).closest('.sub-section-text-form');
    $thisSection = $thisForm.prev('div');
    $thisForm.fadeOut 'fast', () ->
      $thisSection.fadeIn('fast');  

  $('.tile-content .toggleShare').on 'click', (e) ->
    e.preventDefault();
    $(this).closest('.tile-content').find('.shareBinder').slideToggle 'fast'
