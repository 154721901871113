class @Dashboard
  constructor: ->
    # Load activity for respective tab when clicked
    $('ul#activity-tabs > li > a[href^="#container-"]').on 'click', (e) ->
      self = $(this)
      locale = document.documentElement.lang
      # Make AJAX call to load activity if it hasn't been loaded yet
      container = $('#container-' + self.data('id'))
      activity_area = container.find('.activity-area')
      activity_error = container.find('.activity-error')
      unless self.data('loaded')
        $.ajax
          url: '/' + locale + '/dashboard/activity/' + self.data('id')
          method: 'GET'
          success: (html) ->
            self.data('loaded', true)
            activity_area.html(html)
            activity_error.hide()
            activity_area.show()
          error: () ->
            activity_area.hide()
            activity_error.show()

    # Initially select first tab if it exists
    $('ul#activity-tabs > li > a[href^="#container-"]:first').trigger 'click'

    # Visual changes for toggling dashboard containers
    $('#configure form').find('li:not(.submit)').on 'keypress', (e) ->
      e.preventDefault()
      e.stopPropagation()
      self = $(this)
      checkbox = self.find('input[type="checkbox"]')
      checkbox.prop('checked', !checkbox.prop('checked'))

      # TODO: Dynamic limit and translations?
      if self.closest('.list-config').find('input[type="checkbox"]:checked').length > 10
        checkbox.prop('checked', !checkbox.prop('checked'))
        alert("You can only select up to 10 groups.")

    # Add params to OAuth link
    $('a.btn.google').on 'click', (e) ->
      e.preventDefault()
      params =
        invite_code: $('#user_invite_code').val()
        network: $('#user_network').val()
      $(location).attr('href', $(this).attr('href') + '?' + $.param(params))

window.Dashboard = @Dashboard;
