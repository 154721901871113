class @LibraryResource
  constructor: ->
    modals = $('.modal[id^="modal-resource-"]')
    # Load details
    $('a[data-target^="#modal-resource-"]').on 'click', (e) ->
      self = $(this)
      id = self.data('id')

      # Has this modal been loaded already?
      modal = $(".modal[data-id='#{id}']")
      return if modal.length > 0

      # If we reach this, it means the modal needs to be loaded
      e.preventDefault()
      e.stopPropagation()
      # Show loading modal
      loading_modal = $('#modal-resources-loading')
      loading_modal.find('.modal-body').hide()
      loading_modal.find('.resc-loading').show()
      loading_modal.modal 'show'
      loading_modal.off()
      loading_modal.on 'shown.bs.modal', (e) ->
        # Get resource via AJAX
        $.ajax
          url: Routes.modal_library_resource_path(id)
          method: 'GET'
          success: (html) ->
            # Add resource modal to DOM
            $('body').append(html)

            # Fix tag links
            tags = $(".resource-tags-#{id}")
            $.each tags.find('span:not(.fa) a'), (index, a) ->
              link = $(a)
              link.parent().replaceWith(LibraryResource.tagHTML(link.html()))
            
            loading_modal.modal 'hide'

            # Show resource modal
            modal = $(".modal[data-id='#{id}']")
            LibraryResource.adjustModalHeight(modal)
            modal.modal 'show'
            LibraryResource.bindModalEvents(modal)
            $('a[href^="#modal-resource-preview"').on 'click', (e) ->
              $.ajax
                url: Routes.generate_public_preview_library_resource_path(id)
                method: 'POST'
                dataType: 'json'
                data: {}
                success: (data) ->
                  $("#public-preview-#{id}").attr("src", "https://drive.google.com/viewer?embedded=true&hl=en-US&url=#{Routes.public_preview_library_resource_path(id, data.token)}")
            # Initialize WYSIWYG editor for description and select2 for tags
            WysiwygEditor.initialize(modal)
            Taggings.initialize_select2(modal)
          error: ->
            # Show error message
            loading_modal.find('.modal-body').hide()
            loading_modal.find('.resc-error').show()

  @bindModalEvents: (modal) ->
    # Show edit form
    modal.on 'click', '.btn-edit-resc', ->
      self = $(this)
      details = self.closest('.details-resc')
      form = details.siblings('.edit-resc')
      # Hide flash message
      details.find('.alert').addClass 'd-none'
      details.fadeOut 'fast', ->
        form.fadeIn 'fast'

    # Edit resource
    modal.on 'click', '.btn-edit-resc-form', (e) ->
      # Prevent form submission
      e.preventDefault()

      # Store objects to be used later
      self = $(this)
      form = self.closest('.edit-resc')
      details = form.siblings('.details-resc')

      # Get id of resource
      id = modal.data('id')

      # Disable submit button and show loading icon
      Utils.btnLoading(self)

      # AJAX update
      $.ajax
        url: Routes.library_resource_path(id)
        method: 'PUT'
        dataType: 'json'
        data: form.find('form').serialize()
        success: (data) ->
          # Hide flash messages
          details.find('.alert').addClass 'd-none'

          if data['success']
            # Display success message
            details.find('.alert-success').removeClass 'd-none'

            try
              # Change DOM element text to reflect updated resource (both modal and listing)
              json = JSON.parse(data['object'])

              # Title
              $(".resource-title-#{id}").html(json['title'])

              # Description
              if json['description'] == ''
                $(".resource-description-#{id}").html('No description')
              else
                $(".resource-description-#{id}").html(json['description'])

              # URL
              urlElement = $(".resource-url-#{id}")
              if json['cems_source']['url'] == ''
                urlElement.attr('href', '#')
                urlElement.html('')
                urlElement.closest('li').addClass('d-none')
              else
                truncatedUrl = json['cems_source']['url'].substring(0, 30)
                truncatedUrl += '...' if json['cems_source']['url'].length > 30
                urlElement.attr('href', json['cems_source']['url'])
                urlElement.html(truncatedUrl)
                urlElement.closest('li').removeClass('d-none')

              # Tags
              tags = $(".resource-tags-#{id}")
              tags.find('span:not(.fa)').remove()
              if json['tags'].length == 0
                tags.append('<span>None</span>')
              else
                $.each json['tags'], (index, tag) ->
                  tags.append(LibraryResource.tagHTML(tag['value']))

              # Resource type
              $(".resource-type-#{id}").html(json['network_library_resource_type']['name'])
            catch error
              # Display error message
              details.find('.alert-danger').removeClass 'd-none'
          else
            # Display error message
            details.find('.alert-danger').removeClass 'd-none'

          # Enable submit button and hide loading icon
          Utils.btnLoading(self, false)

          # Show details pane again
          form.fadeOut 'fast', ->
            details.fadeIn 'fast'
            form.find('.alert').addClass 'd-none'
        error: () ->
          # Display error message
          form.find('.alert').addClass 'd-none'
          form.find('.alert-danger').removeClass 'd-none'

          # Enable submit button and hide loading icon
          Utils.btnLoading(self, false)

    # Cancel edit
    modal.on 'click', '.btn-cancel-resc-form', ->
      self = $(this)
      form = self.closest('.edit-resc')
      form.find("form").trigger('reset')
      details = form.siblings('.details-resc')
      form.fadeOut 'fast', ->
        details.fadeIn 'fast'

    # Show delete form
    modal.on 'click', '.btn-delete-resc', ->
      self = $(this)
      details = self.closest('.details-resc')
      form = details.siblings('.delete-resc')
      # Hide message
      details.find('.alert').addClass 'd-none'
      details.fadeOut 'fast', ->
        form.fadeIn 'fast'

    # Confirm delete
    modal.on 'click', '.btn-confirm-delete-resc', (e) ->
      self = $(this)
      # Disable delete button and show loading icon
      Utils.btnLoading(self)

    # Cancel delete
    modal.on 'click', '.btn-cancel-delete-resc', ->
      self = $(this)
      form = self.closest('.delete-resc')
      details = form.siblings('.details-resc')
      form.fadeOut 'fast', ->
        details.fadeIn 'fast'
        form.find('.alert-danger').addClass 'd-none'
    
    # When the modal is closed cancel with delete or edit panels
    
    modal.on 'click', '.btn-close-modal', ->
      editScreen = $("body").find( ".edit-resc" )
      deleteScreen = $("body").find( ".delete-resc" )
      details = $("body").find('.details-resc')
      editScreen.find("form").trigger('reset')
      form = editScreen
      if deleteScreen.is(':visible')
        form = deleteScreen
      form.fadeOut 'fast', ->
        details.fadeIn 'fast'
    # Window resize
    $(window).on 'resize', ->
      LibraryResource.adjustModalHeight(modal)

  # Calculate appropriate height
  #
  # Modal
  #   margin-top: 30; margin-bottom: 30;
  #   padding-top: 70; padding-bottom: 70;
  # Header
  #   height: 40; padding-top: 20; padding-bottom: 20;
  # Nav Tabs
  #   height: 40, border-bottom: 1
  #
  # Total: 321
  @adjustModalHeight: (modal) ->
    h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 321
    modal.find('.pre-scrollable').css({ 'min-height': 100, 'max-height': h, 'overflow-y': 'auto' })

  # Return the HTML for an individual tag given its name
  @tagHTML: (name) ->
    html = '<span class="badge light-bg">'
    html += "<a href='#{window.location.href}"
    html += if window.location.href.indexOf('?') == -1 then '?' else '&'
    html += "tag=#{name}'>#{name}</a>"
    html += '</span>'
    html += "\n"
    return html

window.LibraryResource = @LibraryResource;
