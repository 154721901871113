class @Onboarding
  constructor: ->
    select2_options = {
      theme: 'bootstrap'
    }
    $('.select2-onboarding').select2(select2_options)
    $('#ob-loading').addClass 'd-none'
    $('.survey-question-wrapper:first, #ob-change-question').removeClass 'd-none'

    # Event to trigger colour filling for onboarding selections
    $('.select-slide').on 'change', '.select-change', (e) ->
      $(this).closest('label').toggleClass 'off on'


    # Event handler for the "Next" button
    $('#ob-next').on 'click', (e) ->
      e.preventDefault()
      current_question = $(".ob-question:not(.d-none)")
      nextQuestion = $('#ob-question-' + (parseInt(current_question.data('onboarding-question'), 10) + 1))

      # If the next question exists, swap question slides
      # otherwise, hide the question slides and show the done button
      if nextQuestion.length > 0
        current_question.toggleClass 'd-none'
        nextQuestion.removeClass('d-none').hide().fadeIn('slow')
      else
        $('.select-slide').toggleClass 'd-none'
        Onboarding.submit_responses()

    # Trigger in-profile selections
    $('.in-profile').on 'click', '.heading-page', (e) ->
      e.preventDefault()
      # Show first slide
      $('#grade').fadeIn 'fast'


  @submit_responses: ->
    wrapper = $('.survey-wrapper')
    $('#ob-submitting').toggleClass('d-none')
    responses = []
    $('.survey-question-wrapper').each (index, element) ->
      question_element = $(element)
      question_id = question_element.data('question-id')

      if question_element.hasClass('select-one')
        answers = [question_element.find('select').eq(0).select2('data')[0].id]
      else if question_element.hasClass('select-multiple')
        answers = []
        responses = question_element.find('select').eq(0).select2('data')
        $.each responses, (idx) ->
          answers.push(responses[idx].id)

      responses.push({
        question_id: question_id,
        answer_ids: answers
      })

    $.ajax
      url: '/welcome'
      method: 'POST'
      contentType: 'application/json',
      data: JSON.stringify({
        survey_responses: responses,
        survey_respondable: {
          type: wrapper.data('survey-respondable-type'),
          id: wrapper.data('survey-respondable-id'),
        },
      })
      success: (data) ->
        $('#ob-submitting').toggleClass('d-none')
        $('#ob-done').removeClass('d-none').hide().fadeIn('slow')
      error: () ->
        $('#ob-submitting').toggleClass('d-none')
        $('#ob-error').removeClass('d-none').hide().fadeIn('slow')

window.Onboarding = @Onboarding;
