class @NotificationSettings
  constructor: ->
    $('body').on 'click', '.remove-subscription', (e) ->
      e.preventDefault()
      self = $(this)

      # Get confirmation from user before proceeding with unsubscription
      if confirm(self.data('text'))
        $.ajax
          url: Routes.user_subscription_path($('#current-user').data('id'), self.data('id'))
          method: 'DELETE'
          dataType: 'json'
          success: (data) ->
            # Get container for all subscriptions, grouped by content type
            panels = self.closest('.panel-group')

            # If the subscription to be removed is the last of its type, remove the panel for the type as well
            # Otherwise, just remove the subscription
            if self.closest('tbody').find('tr').length <= 1
              self.closest('.panel').remove()
            else
              self.closest('tr').remove()

            # If the subscription was the last one for the user, remove subscriptions container entirely
            # and display the blank state message
            if panels.find('.panel').length == 0
              panels.siblings('.d-none').removeClass('d-none')
              panels.siblings('.subheader').remove()
              panels.remove()

window.NotificationSettings = @NotificationSettings;
