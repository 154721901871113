class @Share
  constructor: ->
    $('body').on 'change', '.share-forum-select select', ->
      # Store $(this) in variable for later use
      self = $(this)

      # Get selection
      container_type = self.find('option:selected').text().toLowerCase()

      # Show/Hide forms accordingly
      parent = self.closest('fieldset')
      parent.siblings('[id^="share-"]').slideUp 'fast'
      unless container_type == ''
        parent.siblings("[id^='share-#{container_type}']").slideDown 'fast'

    $('body').on 'change', '.share-classroom-select select, .share-group-select select', ->
      # Store $(this) in variable for later use
      self = $(this)

      # Get container type
      container_type = self.closest('.tab-pane').find('.share-forum-select select option:selected').val()

      # Get selected classroom ID
      id = self.val()
      channel_select = self.closest('fieldset').next('fieldset').find('select')
      channel_submit = channel_select.closest('fieldset').next('fieldset').find('button[type="submit"]')
      if id == ''
        channel_select.closest('fieldset').slideUp 'fast'
        channel_submit.closest('fieldset').slideUp 'fast'
      else
        # Make AJAX call to get channels associated with the classroom
        $.ajax
          url: Routes.forum_channels_path(container_type, id)
          method: 'GET'
          dataType: 'json'
          success: (data) ->
            channel_select.children('option').remove()
            channel_select.append('<option value=""></option>')
            $.each data, (index, channel) ->
              channel_select.append("<option value='#{channel.id}'>#{channel.name}</option>")
            channel_select.closest('fieldset').slideDown 'fast'

    $('body').on 'change', '.share-binder-select select', ->
      # Store $(this) in variable for later use
      binder_select = $(this)
      binder_section_select = binder_select.closest('fieldset').next('fieldset.share-binder-section-select').find('select')
      binder_submit = binder_section_select.closest('fieldset').next('fieldset').find('button[type="submit"]')

      # Get selected binder ID
      id = binder_select.val()

      # If blank value is selected, hide binder section select and submit button
      # Otherwise, populate binder section select and show it
      if id == ''
        binder_section_select.closest('fieldset').slideUp 'fast'
        binder_submit.closest('fieldset').slideUp 'fast'
      else
        $.ajax
          url: Routes.binder_path(id)
          method: 'GET'
          dataType: 'json'
          success: (data) ->
            binder_section_select.children('option').remove()
            binder_section_select.append('<option value=""></option>')
            $.each data, (index, binder_section) ->
              binder_section_select.append("<option value='#{binder_section.id}'>#{binder_section.title}</option>")
            binder_section_select.closest('fieldset').slideDown 'fast'

    $('body').on 'change', '.share-binder-section-select select, .share-classroom-channel-select select, .share-group-channel-select select', ->
      # Store $(this) in variable for later use
      self = $(this)

      # Get selected binder section ID
      id = self.val()

      # If blank value is selected, hide submit button
      # Otherwise, show submit button
      if id == ''
        self.closest('fieldset').next('fieldset').slideUp 'fast'
      else
        self.closest('fieldset').next('fieldset').slideDown 'fast'

window.Share = @Share;
