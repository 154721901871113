$ ->
  $('#start-tour').click (e) ->
    e.preventDefault()
    pageTour = $('#page-tour')

    if pageTour.length > 0
      pageTour.modal()
    else
      startJoyRide()


  resetSlides = () ->
    # Hide all the slides except the first one
    $('.modal.tour .slides').hide()
    $('.modal.tour .slides[data-slide=1]').show()


  resetSlides()

  $('#page-tour .slides a.next').click (e) ->
    e.preventDefault()
    parent = $(this).closest('.slides')

    # get the next slide number
    nextSlide = parseInt(parent.data('slide'), 10) + 1

    parent.toggle
      effect: 'slide'
      direction: 'left'
      complete: () ->
        $(".modal.tour .slides[data-slide=#{nextSlide}]").toggle
          effect: 'slide'
          direction: 'right'

  startJoyRide = () ->
    # Start the joyride
    jt = $("#joyride-tour")

    # Only start if we actually have a joyride
    if jt.length > 0
      $("#joyride-tour").joyride
        autoStart : true

  $('#page-tour .slides a.last').click (e) ->
    e.preventDefault()
    $('#page-tour').modal('hide')

    # Reset the slides
    setTimeout(resetSlides, 500)

    startJoyRide()
