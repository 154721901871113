class @Wikis
  constructor: ->
    # Toggle inline add new Wiki page form
    $('body').on 'click', '.btn-wiki-add', (e) ->
      $(this).closest('.btn-toolbar').next('.add-sub-form').slideToggle('fast')

    # Wiki page ordering
    $('body').on 'click', '.btn-wiki-directions', (e) ->
      e.preventDefault()
      self = $(this)
      direction = $(this).data('direction')
      # Determine if this is a valid move
      switch direction
        when 'left' then error = self.closest('ul').attr('id') == 'wikinav'
        when 'up' then error = self.closest('ol').parent().is(':first-child')
        when 'down' then error = self.closest('ol').parent().is(':last-child')
        when 'right' then error = self.closest('ol').parent().is(':first-child')
      # If invalid, show error message
      if error
        $('#architect-errors').children().hide()
        $("#architect-errors .#{direction}").show()
        $('#architect-errors').show()
        $('#architect-success').children().hide()
        $('#architect-success').hide()
        return
      # Otherwise, proceed with move
      # Disable direction buttons while move is happening to prevent users from sending multiple requests at once
      $('.btn-wiki-directions').prop('disabled', true)
      $.ajax
        url: $(this).attr('href')
        method: 'post'
        dataType: 'json'
        data:
          direction: direction
        success: (data) ->
          # Update DOM to reflect move
          switch direction
            when 'left'
              destination = self.closest('ul').closest('li')
              page = self.closest('ol').parent().detach()
              destination.after(page)
            when 'up'
              destination = self.closest('ol').parent().prev()
              page = self.closest('ol').parent().detach()
              destination.before(page)
            when 'down'
              destination = self.closest('ol').parent().next()
              page = self.closest('ol').parent().detach()
              destination.after(page)
            when 'right'
              destination = self.closest('ol').parent().prev().children('ul')
              page = self.closest('ol').parent().detach()
              destination.append(page)
          # Enable direction buttons and hide error message
          $('.btn-wiki-directions').prop('disabled', false)
          $('#architect-errors').children().hide()
          $('#architect-errors').hide()
          $('#architect-success').children().show()
          $('#architect-success').show()

    # When submit buttons are clicked on the add subpage form,
    # this event handler wires up the status dropdown before the form submits anything
    $('body').on 'click', '.wiki-block .add-sub-form button', (e) ->
      buttonClicked = $(e.target)
      statusSelect = buttonClicked.siblings('select')
      if statusSelect.length > 0
        statusSelect.val(buttonClicked.data('status'))


window.Wikis = @Wikis;
