class @ResourceAdd
  constructor: ->
    # ********** VARIABLES **********
    self = @
    @modal = $('#modalAddResources')
    @form = @modal.find('.tab-pane.active form')
    @submitButtons = @modal.find('.modal-footer .btn')
    @fileList = new Array()
    @uploadList = @modal.find('.uploaded')
    @progressBar = @modal.find('.progress .progress-bar')
    @locationSelector = {
      map: null
      search: null
      markers: []
    }
    @options = {
      context_type: self.modal.data('context-type')
      context_id: self.modal.data('context-id')
      resource_attachable:
        type: null
        id: null
      library_resource:
        from_attachment: false
    }
    # ********** / VARIABLES **********

    # If a specific upload tab needs to be shown, hide the others
    $('[data-target="#modalAddResources"]').on 'click', ->
      tab = $(this).data('modaltab')
      tabs = self.modal.find('.modal-add-resources-tab')
      if tab
        ResourceAdd.selectTab(self.modal, tab)
      else
        tabs.show()
        self.modal.find('[class*="save-and-"]').show()

    @modal.on 'data-editor-change', ->
      self.options.library_resource.from_attachment = true unless self.modal.data('no-attach')
      self.options.resource_attachable.type = self.modal.data('content-block-type')
      self.options.resource_attachable.id = self.modal.data('content-block-id')
      self.form = $('#modal-add-resources-library').find('form')
      self.reset(self)

    # When a different tab is selected update the form variable accordingly and reset its contents
    @modal.on 'shown.bs.tab', '.modal-add-resources-tab > a[data-toggle="tab"]', (e) ->
      target = $(e.target).attr('href')
      self.form = $(target).find('form')
      self.reset(self)


    # Handler for when a save button is clicked
    @modal.on 'click', '.save-and-close:not(.disabled), .save-and-view:not(.disabled)', ->
      # Disable buttons and show loading icons
      buttons = self.modal.find('[class*="save-and-"]')
      Utils.btnLoading(buttons)

      switch self.form.closest('.tab-pane').attr('id')
        when 'modal-add-resources-library'
          self.attachResource(self, $(this))
        when 'modal-add-resources-files'
          self.uploadFiles(self, buttons, $(this))
        when 'modal-add-resources-links', 'modal-add-resources-google-drive', 'modal-add-resources-books', 'modal-add-resources-locations'
          self.saveResource(self, buttons, $(this))
        else
          console.log 'Unable to determine active pane'
          Utils.btnLoading(buttons, false)

    # Reset modal to initial state when it is closed
    @modal.on 'hidden.bs.modal', ->
      self.reset(self, true)

    # Initialize file upload on page load
    self.initializeUpload(self)

  # ********** GENERAL **********
  # Reset the form to its initial state
  formReset: (form) ->
    form[0].reset()
    form.find('.modal-add-resources-tags').trigger('change')

  # Reset the modal to its initial state
  reset: (self, modalClose=false) ->
    # Alerts
    self.form.find('.alert').addClass('d-none')

    # Form
    self.formReset(self.form)

    # Submit buttons
    self.submitButtons.addClass('disabled')

    # Active tab
    switch self.form.closest('.tab-pane').attr('id')
      when 'modal-add-resources-library' then self.initializeResourceAttach(self)
      when 'modal-add-resources-files' then self.initializeUpload(self)
      when 'modal-add-resources-links' then self.initializeLink(self)
      when 'modal-add-resources-google-drive'
        self.initializeGoogleDrive(self)
        # Show files from root Google Drive folder
        self.loadGoogleDrive(self, $('#current-user').data('id'))
      when 'modal-add-resources-books' then self.initializeBook(self)
      when 'modal-add-resources-locations'
        self.initializeLocation(self)
        # Reinitialize map (e.g. initial center and zoom)
        self.mapInit(self)

    # Editor indicator
    if modalClose
      id = self.modal.attr('data-editor')
      $("[data-editor='#{id}']").removeAttr('data-editor')
      self.options.library_resource.from_attachment = false
      self.options.resource_attachable.type = null
      self.options.resource_attachable.id = null
      # No longer in editor context, so hide "From library" tab
      self.modal.find('#modal-add-resources-library-tab').addClass('d-none').find('a').removeClass('active')
      self.modal.find('#modal-add-resources-library').removeClass('in active show')
      if self.form.closest('.tab-pane').attr('id') == 'modal-add-resources-library'
        self.modal.find('#modal-add-resources-files-tab a').addClass('active')
        self.modal.find('#modal-add-resources-files').addClass('in active show')
        self.initializeUpload(self)

  # Generic method that does presence validation of form fields
  validate: (self, fields=new Array()) ->
    valid = true

    # Resource-specific fields can be passed
    for field in fields
      if !field.value
        valid = false

    # Resource type
    if self.form.find('.modal-add-resources-type').val() == ''
      valid = false
      
    # Tags
    saved_tag = self.form.find('.modal-add-resources-tags').val()
    if saved_tag.length <= 0
      self.form.find('.modal-add-resources-tags-required').removeClass('d-none')
      valid = false
    else
      self.form.find('.modal-add-resources-tags-required').addClass('d-none')

    unless valid 
      self.submitButtons.addClass('disabled')
      self.submitButtons.attr("disabled", true)
      return

    # Getting here means fields are valid
    self.submitButtons.removeClass('disabled')
    self.submitButtons.attr("disabled", false)

  # Generic method that makes an AJAX call to save the resource
  saveResource: (self, buttons, button) ->
    # Hide error message
    errorAlert = self.form.find('.alert-danger')
    errorAlert.addClass('d-none')

    # Add options to the form data
    formData = self.form.serializeArray()
    formData.push({ name: 'context_type', value: self.options.context_type })
    formData.push({ name: 'context_id', value: self.options.context_id })
    # This will be an attachment if there is an editor associated with the modal
    if self.modal.attr('data-editor') && !self.modal.attr('data-no-attach')
      formData.push({ name: 'resource_attachable_type', value: self.options.resource_attachable.type })
      formData.push({ name: 'resource_attachable_id', value: self.options.resource_attachable.id })
    formData.push({ name: 'library_resource[from_attachment]', value: self.options.library_resource.from_attachment })

    # AJAX create
    $.ajax
      url: Routes.resource_attachments_path()
      method: 'POST'
      dataType: 'json'
      data: $.param(formData)
      success: (data) ->
        if data['id']
          # Show success message
          self.form.find('.alert-success').removeClass('d-none')

          # Handle adding resource into editor and attaching it
          if self.modal.attr('data-editor')
            editor = $('form[data-editor="' + self.modal.attr('data-editor') + '"]').find('.live-editor')
            if self.modal.attr('data-no-attach')
              url = data['cems_source']['url']
              route = Routes.feature_preview_library_resource_path(data['id'])
            else
              url = data['library_resource']['cems_source']['url']
              route = Routes.feature_preview_resource_attachment_path(data['id'])
            if url == null then url = '#' else url += "' target='_blank"
            # Restore cursor/selection
            editor.froalaEditor('selection.restore')
            $.ajax
              url: route
              data:
                text: editor.froalaEditor('selection.text')
              success: (html) ->
                editor.froalaEditor('html.insert', html)
                editor.froalaEditor('undo.saveStep')
            editor.trigger('resource.inserted', [editor, data['id']]) unless self.modal.attr('data-no-attach')

          # Proceed depending on which save button was pressed
          # Timeout used so that user has time to see success message
          window.setTimeout (->
            self.modal.modal('hide') if button.hasClass('save-and-close')
            if button.hasClass('save-and-view')
              content_path_type = self.modal.data('context-type')
              content_path_id = self.modal.data('context-id')
              if window.location.href == Routes.content_path(content_path_type, content_path_id)
                window.location.reload()
              else
                window.location.replace(Routes.content_path(content_path_type, content_path_id))
          ), 1000
        else
          # Show error message
          errorAlert.html(data['error'])
          errorAlert.removeClass('d-none')
          # Hide success message
          self.form.find('.alert-success').addClass('d-none')
      error: (error) ->
        # Show error message
        errorAlert.html(error)
        errorAlert.removeClass('d-none')
        # Hide success message
        self.form.find('.alert-success').addClass('d-none')
      complete: ->
        # Disable buttons and hide loading icons
        Utils.btnLoading(buttons, false)

  # Emulate a tab being selected/clicked
  @selectTab: (modal, tab) ->
    tabs = modal.find('.modal-add-resources-tab')
    modal.find("a[href='#modal-add-resources-#{tab}']").tab('show')
    modal.find('.save-and-view').hide()
    tabs.hide()
  # ********** / GENERAL **********

  # ********** LIBRARY **********
  # Initialization
  initializeResourceAttach: (self) ->
    # Unbind events
    self.form.off('change.resourceAdd').off('keyup.resourceAdd').off('click.resourceAdd')

    # Clear search results
    self.form.find('.library-resource-results .results-list > li:not(.library-resource-template)').remove()
    self.form.find('.library-resource-results .results-list').parent().addClass('d-none')
    self.form.find('.library-resource-results > .loading').addClass('d-none')

    # Validation
    self.form.on 'change.resourceAdd', 'input[type="radio"]', ->
      self.submitButtons.removeClass('disabled')
      self.submitButtons.attr("disabled", false)

    # Run a search for library resources
    handleResourceSearch = (self, e) ->
      e.preventDefault()
      # Get search query
      query = self.form.find('[name="library_resource_search"]').val()
      # Perform search
      self.searchLibraryResources(self, query, self.form.find('.btn-library-resource-search'))

    self.form.on 'keyup.resourceAdd', '[name="library_resource_search"]', (e) ->
      handleResourceSearch(self, e) if e.keyCode == 13
    self.form.on 'click.resourceAdd', '.btn-library-resource-search', (e) ->
      handleResourceSearch(self, e)

  searchLibraryResources: (self, query, btn) ->
    # Don't do anything if the search query is empty
    return if query == ''

    # Hide result list
    self.form.find('.library-resource-results .results-list').parent().addClass('d-none')

    # Show loading indicators
    Utils.btnLoading(btn)
    loading = self.form.find('.loading')
    loading.removeClass('d-none')

    # Hide previous errors
    self.form.find('.alert').addClass('d-none')
    # Remove results from previous search
    self.form.find('.library-resource-results .results-list > li:not(.library-resource-template)').remove()

    # Reset form since new resources will be loaded
    self.formReset(self.form)
    self.form.find('[name="library_resource_search"]').val(query)
    self.submitButtons.addClass('disabled')

    # AJAX call to retrieve resources
    $.ajax
      url: Routes.library_resources_path()
      dataType: 'json'
      data:
        search: query
        container: if self.options.context_type != 'user' then self.options.context_id else null
      success: (data) ->
        # Loading is finished
        loading.addClass('d-none')

        # Render library resources
        for resource in data
          html = self.form.find('.library-resource-template').clone().removeClass('library-resource-template d-none')
          html.find('input[name="library_resource_id"]').val(resource['id'])
          html.find('input[name="library_resource_id"]').attr('id', "from_library_#{resource['id']}")
          html.find('.checkbox-label').attr('for', "from_library_#{resource['id']}")
          html.find('.title').html(resource['title'])
          html.find('.description').html(resource['description'])
          html.find('.preview').attr('src', Routes.preview_library_resource_path(resource['id']))
          if resource['container_id'] == null
            html.find('li .fa').removeClass('fa-{{icon}}').addClass('fa-user green')
            html.find('.resource-type-personal, .share-warning').removeClass('d-none')
          else
            html.find('li .fa').removeClass('fa-{{icon}}').addClass('fa-group blue')
            html.find('.resource-type-group').removeClass('d-none')
          self.form.find('.library-resource-results .results-list').append(html)

          # Display results list
          self.form.find('.library-resource-results .results-list').parent().removeClass('d-none')
      complete: ->
        Utils.btnLoading(btn, false)

  _attachResource: (self, ajaxData, featurePreviewRoute, noAttach, button) ->
    # Hide error message
    errorAlert = self.form.find('.alert-danger')
    errorAlert.addClass('d-none')
    # Had to pull this out because closing the modal was wiping the editor id
    editor = $('form[data-editor="' + self.modal.attr('data-editor') + '"]').find('.live-editor')

    # Attach resource
    $.ajax
      url: ajaxData['url']
      method: ajaxData['method']
      dataType: 'json'
      data: ajaxData['data']
      success: (data) ->
        if data['id']
          # Show success message
          self.form.find('.alert-success').removeClass('d-none')
          # Handle adding resource into editor
          url = if noAttach then data['cems_source']['url'] else data['library_resource']['cems_source']['url']
          if url == null then url = '#' else url += "' target='_blank"
          # Restore cursor/selection
          editor.froalaEditor('selection.restore')
          $.ajax
            url: Routes[featurePreviewRoute](data['id'])
            data:
              text: editor.froalaEditor('selection.text')
            success: (html) ->
              editor.froalaEditor('html.insert', html)
              editor.froalaEditor('undo.saveStep')
          editor.trigger('resource.inserted', [editor, data['id']]) unless noAttach
        else
          # Show error message
          errorAlert.html(data['error'])
          errorAlert.removeClass('d-none')
          # Hide success message
          self.form.find('.alert-success').addClass('d-none')
      complete: ->
        Utils.btnLoading(button, false)

  # Dropdown's hook into the auto-attach on upload functionality of the resource add modal.
  # 
  # Its easier for us in the long run to have a new method here which wraps the existing attach functionality,
  # but offers us a slightly better interface by way of passing the request data in. While this means that
  # the dropzone component needs to pull the resource_attachable data out, it also means that since the end goal
  # is to move off of the CoffeeScript module into the vueJS component anyway, not much needs to change besides
  # redirecting the calls and re-implementing the underlying functionality (which should be easy since the entire)
  # modal will eventually be a VueJS component itself. :)
  dropzoneAttachResource: (self, button, uploadData) ->
    button = $('.save-and-close, .save-and-view, .save-asset');
    self._attachResource(
      self,
      {
        url: Routes.resource_attachments_path(),
        method: 'POST',
        data: {
          resource_attachable_id: uploadData.resource_attachable_id,
          resource_attachable_type: uploadData.resource_attachable_type,
          library_resource_id: uploadData.library_resource_id
        }
      },
      'feature_preview_resource_attachment_path',
      false,
      button
    );

  attachResource: (self, button) ->
    # Two cases:
    #   1) Editor that does not have attachments (e.g. group description)
    #   2) Editor that has attachments (e.g. content block)
    if self.modal.attr('data-no-attach')
      self._attachResource(self, {
        url: Routes.library_resource_path(self.form.find('input[name="library_resource_id"]:checked').val())
        method: 'GET'
        data: {}
      }, 'feature_preview_library_resource_path', true, button)
    else
      self._attachResource(self, {
        url: Routes.resource_attachments_path()
        method: 'POST'
        data:
          resource_attachable_type: self.options.resource_attachable.type
          resource_attachable_id: self.options.resource_attachable.id
          library_resource_id: self.form.find('input[name="library_resource_id"]:checked').val()
      }, 'feature_preview_resource_attachment_path', false, button)
  # ********** / LIBRARY **********

  # ********** UPLOAD **********
  # Initialization
  initializeUpload: (self) ->
    # Unbind events
    self.form.off('change.resourceAdd').off('drop.resourceAdd').off('dragover.resourceAdd').off('click.resourceAdd')

    # Validations
    self.form.on 'change.resourceAdd', 'input[name="file_count_valid"], select', ->
      self.validate(self, $.makeArray(self.form.find('.file-count-valid')))

    # Reset progress bar
    self.progressBar.attr('aria-valuenow', '0')
    self.progressBar.css('width', '0%')

    # Clear file and upload list
    self.fileList = new Array()
    self.uploadList.find('li').remove()

    # Only allow drag and drop on upload area
    $(document).bind 'drop.resourceAdd dragover.resourceAdd', (e) ->
      e.preventDefault()

    # Event for when user selects or drags & drops files for upload


    # Remove a file from the upload list
    self.modal.on 'click.resourceAdd', '.uploaded .btn-delete', (e) ->
      e.preventDefault()

      # Get element in upload list to remove
      li = $(this).tooltip('destroy').parent()

      # Remove from file list
      id = li.data('id')
      self.fileList = self.removeFromList(self, id)

      # Invalidate file count if nothing to upload
      if self.fileList.length == 0
        self.form.find('.file-count-valid').val('').trigger('change')

      # Remove from upload list
      li.remove()

  # HTML for an item in the upload list
  @fileHTML: (lastModified, name) ->
    html = "<li data-id='#{lastModified}_#{btoa(name)}'>"
    html += " #{name} "
    html += '<a href="#" class="btn-delete" data-original-title="Remove this upload" data-toggle="tooltip">'
    html += '<span class="fa fa-times red"></span>'
    html += '</a></li>'

  # Add given files to file and upload lists
  addFiles: (self, files) ->
    $.each files, (index, file) ->
      li = self.uploadList.find("li[data-id='#{file.lastModified}_#{btoa(file.name)}']")
      # Add file to list if it hasn't already been added
      if li.length == 0
        self.fileList.push(file)
        self.uploadList.append(ResourceAdd.fileHTML(file.lastModified, file.name))
      else
        li.effect('highlight', {}, 1000)

      # Validate file count
      if self.fileList.length > 0
        self.form.find('.file-count-valid').val('true').trigger('change')

      # Reset form input
      self.form.find('input[type="file"]').val('')
      # Reset progress bar
      self.progressBar.attr('aria-valuenow', '0')
      self.progressBar.css('width', '0%')

  # Remove file from file list
  removeFromList: (self, id) ->
    $.each self.fileList, (index, file) ->
      if self.fileList.hasOwnProperty(index) && id == "#{file.lastModified}_#{btoa(file.name)}"
        self.fileList.splice(self.fileList.indexOf(file), 1)
        return self.fileList

  # Validate and upload files in file list
  uploadFiles: (self, buttons, button) ->
    # Reset progress bar
    self.progressBar.attr('aria-valuenow', '0')
    self.progressBar.css('width', '0%')

    # Number of remaining files to upload
    toUpload = self.fileList.length
    # % to increase progress bar by for each file
    increase = 100 / toUpload

    # Iterate through files to upload
    $.each self.fileList, (index, file) ->
      # Build FormData for each file
      formData = new FormData()
      # This will be an attachment if there is an editor associated with the modal
      if self.modal.attr('data-editor') && !self.modal.attr('data-no-attach')
        formData.append('resource_attachable_type', self.options.resource_attachable.type)
        formData.append('resource_attachable_id', self.options.resource_attachable.id)
      formData.append('library_resource[temp_file]', file, file.name)
      $.each self.form.find('.modal-add-resources-tags').val(), (index, tag) ->
        formData.append('library_resource[temp_tags][]', tag)
      formData.append('library_resource[network_library_resource_type_id]', self.form.find('.modal-add-resources-type').val())
      formData.append('library_resource[from_attachment]', self.options.library_resource.from_attachment)
      formData.append('context_type', self.options.context_type)
      formData.append('context_id', self.options.context_id)

      # Find file in upload list
      li = self.uploadList.find("li[data-id='#{file.lastModified}_#{btoa(file.name)}']")

      # Add loading icon
      li.children('.fa').remove()
      li.prepend('<span class="fa fa-spinner fa-spin"></span>')

      # AJAX create
      $.ajax
        url: Routes.resource_attachments_path()
        method: 'POST'
        processData: false
        contentType: false
        dataType: 'json'
        data: formData
        success: (data) ->
          # Replace loading icon with appropriate icon given status
          li.children('.fa').remove()
          if data['id']
            li.prepend('<span class="fa fa-check green"></span>').find('a.btn-delete').remove()
            # Remove file from file list since it has successfully been uploaded
            self.fileList = self.removeFromList(self, li.data('id'))
            # If this modal is associated with an editor, insert the uploaded file
            if self.modal.attr('data-editor')
              editor = $('form[data-editor="' + self.modal.attr('data-editor') + '"]').find('.live-editor')
              resource_type = if self.modal.attr('data-no-attach') then data['resource_type'] else data['library_resource']['resource_type']
              if resource_type == 'image'
                route = if self.modal.attr('data-no-attach') then 'preview_library_resource_path' else 'preview_resource_attachment_path'
                editor.froalaEditor('image.insert', Routes[route](data['id']), true, { 'resource': data['id'] }, null, JSON.stringify({ id: data['id'] }))
              else
                editor.froalaEditor('selection.restore')
                route = if self.modal.attr('data-no-attach') then 'feature_preview_library_resource_path' else 'feature_preview_resource_attachment_path'
                $.ajax
                  url: Routes[route](data['id'])
                  data:
                    text: editor.froalaEditor('selection.text')
                  success: (html) ->
                    editor.froalaEditor('html.insert', html)
                    editor.froalaEditor('undo.saveStep')
              editor.trigger('resource.inserted', [editor, data['id']]) unless self.modal.attr('data-no-attach')
          else
            li.prepend('<span class="fa fa-exclamation-circle red"></span>')
        error: (error) ->
          li.children('.fa').remove()
          li.prepend('<span class="fa fa-exclamation-circle red"></span>')
        complete: () ->
          # Decrement remaining files to upload counter
          toUpload -= 1
          # Increase progress bar
          self.progressBar.attr('aria-valuenow', parseFloat(self.progressBar.attr('aria-valuenow')) + increase)
          self.progressBar.css('width', "#{self.progressBar.attr('aria-valuenow')}%")
          # Check if all upload requests complete
          if toUpload == 0
            # Disable buttons and hide loading icons
            Utils.btnLoading(buttons, false)

            # If all uploads were successful, take action according to button pressed
            if self.fileList.length == 0
              window.setTimeout (->
                self.modal.modal('hide') if button.hasClass('save-and-close')
                if button.hasClass('save-and-view')
                  content_path_type = self.modal.data('context-type')
                  content_path_id = self.modal.data('context-id')
                  if window.location.href == Routes.content_path(content_path_type, content_path_id)
                    window.location.reload()
                  else
                    window.location.replace(Routes.content_path(content_path_type, content_path_id))
              ), 1000
  # ********** / UPLOAD **********

  # ********** LINK **********
  # Initialization
  initializeLink: (self) ->
    # Unbind events
    self.form.off('input.resourceAdd').off('change.resourceAdd')

    # Validations
    self.form.on 'input.resourceAdd', 'input', ->
      self.validate(self, $.makeArray(self.form.find('.link-field')))
    self.form.on 'change.resourceAdd', 'select', ->
      self.validate(self, $.makeArray(self.form.find('.link-field')))
  # ********** / LINK **********

  # ********** GOOGLE DRIVE **********
  # Initialization
  initializeGoogleDrive: (self) ->
    # Unbind events
    self.form.off('change.resourceAdd').off('click.resourceAdd')

    # Validations
    self.form.on 'change.resourceAdd', 'input, select', ->
      self.validate(self, $.makeArray(self.form.find('.google-drive-file-id-field')))

    # Load folders/files from the target folder
    self.modal.on 'click.resourceAdd', '.google-drive-load', (e) ->
      e.preventDefault()
      self.loadGoogleDrive(self, $('#current-user').data('id'), $(e.target).data('id'))

    # Select a file to save
    self.modal.on 'click.resourceAdd', '.google-drive-select', (e) ->
      e.preventDefault()
      target = $(e.target)

      # Set google_drive_file_id in form
      self.form.find('.google-drive-file-id-field').val(target.data('id'))

      # Highlight selected file
      self.form.find('table tr').removeClass('bg-info')
      target.closest('tr').addClass('bg-info')

      # Show tags/resource type form fields
      self.form.find('.form-group').removeClass('d-none')

  # Load folders and files from given Google Drive folder
  loadGoogleDrive: (self, username, folder) ->
    # Show loading message
    self.form.find('> .row:not(.loading), .form-group').addClass('d-none')
    self.form.find('> .row.loading').removeClass('d-none')

    # Reset form since new files will be loaded
    self.formReset(self.form)
    self.form.find('.google-drive-file-id-field').val('')

    # AJAX call to get Google Drive data
    $.ajax
      url: Routes.google_user_content_path(username, folder)
      method: 'GET'
      dataType: 'json'
      success: (data) ->
        # json_redirect is set when authorization to access user's Google Drive is required
        if data['json_redirect']
          # Show authorization required message and set oauth link
          self.form.find('> .row:not(.authorization)').addClass('d-none')
          self.form.find('> .row.authorization').removeClass('d-none').find('a').attr('href', data['redirect'])
        else
          # Render breadcrumbs
          breadcrumbs = self.form.find('.breadcrumbs')
          breadcrumbs.find('.drive-breadcrumb, .fa').remove()
          for breadcrumb in data['breadcrumbs']
            breadcrumbs.append('<span aria-hidden="true" class="fa fa-fw fa-chevron-right"></span>')
            breadcrumbs.append("<a href='#' class='drive-breadcrumb google-drive-load' data-id=\"#{breadcrumb['id']}\">#{breadcrumb['name']}</a>")

          # Display folder and files
          files = self.form.find('.files')
          files.find('tr:not(:first-child)').remove()
          for folder in (data['folders'] || [])
            files.append("<tr>
              <td>
                <span aria-hidden='true' class='fa fa-fw fa-folder'></span>
                <a href='#' class='google-drive-load' data-id=\"#{folder['id']}\">#{folder['name']}</a>
              </td>
              <td>--</td>
              <td>--</td>
            ")
          for file in (data['files'] || [])
            options = {
              year: 'numeric'
              month: 'long'
              day: 'numeric'
              hour: '2-digit'
              minute: '2-digit'
              second: '2-digit'
            }
            date = new Date(file['modified_time']).toLocaleString($('#locale').attr('value'), options)
            files.append("<tr>
              <td>
                <span aria-hidden='true' class='fa fa-fw fa-file-o'></span>
                #{file['name']}
              </td>
              <td>#{date}</td>
              <td><button class='btn btn-sm btn-primary google-drive-select' data-id=\"#{file['id']}\">Select</button></td>
            ")

          # Hide loading and authorization messages
          self.form.find('> .row.loading, > .row.authorization').addClass('d-none')
          self.form.find('> .row:not(.loading):not(.authorization)').removeClass('d-none')

  # ********** / GOOGLE DRIVE **********

  # ********** BOOK **********
  # Initialization
  initializeBook: (self) ->
    # Unbind events
    self.form.off('change.resourceAdd').off('click.resourceAdd')

    # Clear search results
    self.form.find('.book-preview > div, .book-preview ~ .form-group').addClass('d-none')
    self.form.find('.book-preview > :not(.loading):not(.book-template)').remove()

    # Validations
    self.form.on 'change.resourceAdd', 'input, select', ->
      self.validate(self, $.makeArray(self.form.find('.google-book-id-field')))

    # Run a search for books using the Google Books API
    handleBookSearch = (self, e) ->
      e.preventDefault()
      # Get search query
      query = self.form.find('[name="book_search"]').val()
      # Perform search
      self.searchGoogleBooks(self, query, self.form.find('.btn-book-search'))

    self.form.on 'keyup.resourceAdd', '[name="book_search"]', (e) ->
      handleBookSearch(self, e) if e.keyCode == 13
    self.form.on 'click.resourceAdd', '.btn-book-search', (e) ->
      handleBookSearch(self, e)

    # Select a book to save
    self.modal.on 'click.resourceAdd', '.book-select', (e) ->
      e.preventDefault()
      target = $(e.target)

      # Set google_book_id in form
      self.form.find('.google-book-id-field').val(target.closest('.tile-listing').data('id'))

      # Highlight selected book
      self.form.find('.book-preview > .tile-listing:not(.book-template)').removeClass('selected').addClass('d-none')
      target.closest('.tile-listing').addClass('selected').removeClass('d-none')

      # Show tags/resource type form fields
      self.form.find('.form-group').removeClass('d-none')

  searchGoogleBooks: (self, query, btn) ->
    # Don't do anything if the search query is empty
    return if query == ''

    # Show loading indicators
    Utils.btnLoading(btn)
    loading = self.form.find('.loading')
    loading.removeClass('d-none')

    # Hide previous errors
    self.form.find('.alert').addClass('d-none')
    # Remove results from previous search
    self.form.find('.book-preview > :not(.loading):not(.book-template)').remove()
    # Hide tags and resource type fields until a new book is selected
    self.form.find('.book-preview ~ .form-group').addClass('d-none')

    # Reset form since new books will be loaded
    self.formReset(self.form)
    self.form.find('.google-book-id-field').val('')
    self.form.find('[name="book_search"]').val(query)

    # AJAX call to retrieve books
    $.ajax
      url: 'https://www.googleapis.com/books/v1/volumes'
      dataType: 'json'
      data:
        q: query
        maxResults: 5
      success: (data) ->
        # Loading is finished
        loading.addClass('d-none')

        # Render books
        for book in data['items']
          html = self.form.find('.book-template').clone().removeClass('book-template d-none')
          html.attr('data-id', book['id'])
          if book['volumeInfo']['imageLinks'] then html.find('img').attr('src', book['volumeInfo']['imageLinks']['thumbnail'])
          html.find('.title').html(book['volumeInfo']['title'])
          html.find('.description').html(book['volumeInfo']['description'])
          html.find('.title').html(book['volumeInfo']['title'])
          if book['volumeInfo']['authors'] then html.find('.author').html(book['volumeInfo']['authors'].join(', '))
          html.find('.publisher').html(book['volumeInfo']['publisher'])
          if book['volumeInfo']['industryIdentifiers']
            isbn = book['volumeInfo']['industryIdentifiers'].find((i) -> return i['type'] == 'ISBN_13')
            isbn ||= book['volumeInfo']['industryIdentifiers'].find((i) -> return i['type'] == 'ISBN_10')
            html.find('.isbn').html(isbn['identifier']) if isbn
          html.find('.language').html(book['volumeInfo']['language'])
          self.form.find('.book-preview').append(html)
      complete: ->
        Utils.btnLoading(btn, false)
  # ********** / BOOK **********

  # ********** LOCATION **********
  # Initialization
  initializeLocation: (self) ->
    # Unbind events
    self.form.off('input.resourceAdd').off('change.resourceAdd')

    # Hide form fields
    self.form.find('.location-details').addClass('d-none')

    # Validations
    self.form.on 'input.resourceAdd', 'input', ->
      self.validate(self, $.makeArray(self.form.find('.title-field')))
    self.form.on 'change.resourceAdd', 'select', ->
      self.validate(self, $.makeArray(self.form.find('.title-field')))

  # Initialize/Reset location map
  # Source: https://developers.google.com/maps/documentation/javascript/examples/places-searchbox
  mapInit: (self) ->
    if self.locationSelector.map == null
      self.locationSelector.map = new (google.maps.Map)(self.form.find('.location-embed').get(0),
        mapTypeControl: false
        mapTypeControlOptions:
          mapTypeIds: ['roadmap']
        mapTypeId: 'roadmap'
        clickableIcons: false
      )
    map = self.locationSelector.map
    # Center and zoom
    map.setCenter({ lat: 0, lng: 0})
    map.setZoom(2)

    input = self.form.find('[name="location_search"]').get(0)
    if self.locationSelector.search == null
      # Create the search box and link it to the UI element.
      self.locationSelector.search = new (google.maps.places.SearchBox)(input)
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)
    searchBox = self.locationSelector.search

    # Clear out the old markers.
    for marker in self.locationSelector.markers
      marker.setMap(null)
    self.locationSelector.markers = []

    # Bias the SearchBox results towards current map's viewport.
    map.addListener 'bounds_changed', ->
      searchBox.setBounds(map.getBounds())

    # Listen for the event fired when the user selects a prediction and retrieve
    # more details for that place.
    searchBox.addListener 'places_changed', ->
      places = searchBox.getPlaces()

      if places.length == 0
        return

      # Clear out the old markers.
      for marker in self.locationSelector.markers
        marker.setMap(null)
      self.locationSelector.markers = []

      # Reset form fields (except search box)
      self.form.find('.location-details').addClass('d-none')
      search_query = input.value
      self.formReset(self.form)
      self.form.find('[name="location_search"]').val(search_query)

      # For each place, get the icon, name and location.
      bounds = new (google.maps.LatLngBounds)
      places.forEach (place) ->
        if !place.geometry
          console.log 'Returned place contains no geometry'
          return
        icon =
          url: place.icon
          size: new (google.maps.Size)(71, 71)
          origin: new (google.maps.Point)(0, 0)
          anchor: new (google.maps.Point)(17, 34)
          scaledSize: new (google.maps.Size)(25, 25)

        # Create a marker for each place.
        marker = new (google.maps.Marker)(
          map: map
          icon: icon
          title: place.name
          position: place.geometry.location)
        marker.addListener 'click', ->
          self.form.find('.title-field').val(place.name)
          self.form.find('.description-field').froalaEditor('html.set', place.formatted_address)
          self.form.find('.description-field').froalaEditor('undo.saveStep')
          self.form.find('.url-field').val(place.url)
          self.form.find('.location-lat-field').val(place.geometry.location.lat())
          self.form.find('.location-long-field').val(place.geometry.location.lng())
          self.form.find('.location-place-id-field').val(place.place_id)
          self.modal.animate { scrollTop: self.form.find('.location-details').removeClass('d-none').first().offset().top }, 1000
        self.locationSelector.markers.push(marker)

        if place.geometry.viewport
          # Only geocodes have viewport.
          bounds.union(place.geometry.viewport)
        else
          bounds.extend(place.geometry.location)
      map.fitBounds(bounds)
  # ********** / LOCATION **********

window.ResourceAdd = @ResourceAdd;
