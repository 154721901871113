class @Group
  constructor: ->
    self = @
    @panel = $('.group-welcome-panel')
    @toggle = $('.group-welcome-panel-toggle')
    @height = @panel.css('height', 'auto').height()
    @panel.css('height', '')

    # Only show the toggle link if necessary
    if @height > @panel.height()
      @toggle.removeClass('d-none')

    # Toggle height of welcome panel
    @toggle.on 'click', ->
      # Get current state
      # i.e. 'Show more' or 'Show less'
      state = $(this).find(':not(.d-none)')

      # Toggle panel
      if state.hasClass('more')
        self.panel.animate({ height: self.height, overflow: 'inherit' }, 500)
      else
        self.panel.animate({ height: '10rem', overflow: 'hidden' }, 500)

      # Toggle text
      $(this).children().toggleClass('d-none')

window.Group = @Group;
