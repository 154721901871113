$ ->
  $('body').on 'click', '.listing-add a', (e) ->
    target = $(this).data('target')
    unless $(target).length > 0
      e.preventDefault
      self = $(this)
      id = target.substring(target.lastIndexOf('-') + 1)
      $.ajax
        data: 
          type: 'add_idea'
        url: "/resources/#{id}/modal"
        method: 'GET'
        success: (html) ->
          self.closest('body').append(html)
          self.trigger('click')
