$ ->
  $('body').on 'click', '.editCommentLink, .cancelEditComment', (e) ->
    e.preventDefault()
    $(this).closest('li.list-comment').find('.editCommentForm:first').slideToggle('fast')
    $(this).closest('li.list-comment').find('.comment-content:first').slideToggle('fast')

  $('body').on 'click', '.addReplyLink', (e) ->
    e.preventDefault()
    linkClicked = $(this)
    parentId = linkClicked.data('parent')
    commentId = linkClicked.data('comment')
    formDomId = '#comment-' + parentId
    form = $(formDomId).find('.replyCommentForm')
    form.find('[name="parent_comment"]').val(parentId)

    if  parentId != commentId
      prefixText = '@' + linkClicked.data('commenter') + ': '
    else
      prefixText = ''

    form.find('[name="comment"]').val(prefixText)
    form.slideToggle('fast')

  $('body').on 'click', '.cancelReplyComment', (e) ->
    $(this).closest('.replyCommentForm').slideToggle('fast')

  $('body').on 'click', '.submitEditComment', (e) ->
    parent = $(this).closest('li.list-comment').find('.media-body:first')
    parent.find('.comment-content').html(parent.find('textarea[name="comment[comment]"]').val())
    parent.find('.editCommentForm').slideToggle('fast')
    parent.find('.comment-content').slideToggle('fast')
