// JQuery because we're not totally migrated over to vuejs for reactive components yet
// Froala Editor 2.3.5 requires jQuery 3.4.1
//
import jQuery from 'jquery';
window.$ = jQuery;
window.jQuery = jQuery;
require('jquery-ujs');

// Import jQuery-UI
require('jquery-ui/ui/widgets/datepicker.js');

require('./jquery-plugins/jquery.cookie.js');
require('./jquery-plugins/jquery.easyslider.js');
require('./jquery-plugins/jquery.joyride-2.1.js');
